import React, {
	useRef,
	useState,
	useEffect,
	useMemo,
} from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
	H3,
	Body1,
	Body2,
	Box,
	CardContainer,
	Subtitle1,
	Wrapper,
	Spacing,
	DS,
} from "@workshore/nyaari-ds";

import LayoutContainer from "../LayoutContainer/LayoutContainer";
import ShowIf from "../ShowIf/ShowIf";
import { Reminder } from "../Reminder";
import GeneralItemCard from "../GeneralItemCard";
import SearchBox, {
	searchResultsTypes,
} from "../SearchBox/SearchBox";

import useIsTablet from "../../hooks/useIsTablet/useIsTablet";
import { useElementWidth } from "../../hooks/useElementSize/useElementSize";
import { useGetAllMantrasListingQuery } from "../../hooks/graphql/graphql";

import IconTick from "../Icons/IconTick";
import bannerImg from "../../assets/images/journals_banner.png";

import { mantraListingType } from "./type";
import Loader from "../Loader/Loader";
import useDebounce from "../../hooks/useDebounce/useDebounce";

const BannerTextContainer = styled.div`
	padding: 15px 20px;
	background: #e4003f;
	border-radius: ${DS.borderRadius.body1};
	opacity: 0.8;
`;

const StyledListing = styled.div<{
	isSmall: boolean;
}>`
	display: grid;
	grid-gap: 30px;
	${({ isSmall }) => `
	grid-template-columns: ${
		isSmall
			? "1fr"
			: "repeat(auto-fit, minmax(530px, 1fr))"
	};
	`};
`;

const MantraListing = () => {
	const ref = useRef<HTMLDivElement>(null);
	const isTablet = useIsTablet();
	const elementWidth = useElementWidth(ref);
	const isSmall = elementWidth <= 750;
	const allMantras =
		useGetAllMantrasListingQuery();
	const allMantrasData = useMemo(
		() => allMantras.data?.mantras || [],
		[allMantras],
	);
	const [finalMantras, setFinalMantras] =
		useState<mantraListingType>([]);

	const [keyword, setKeyword] = useState("");
	const [searchResults, setSearchResults] =
		useState<searchResultsTypes[]>([]);
	const [showResults, setShowResults] =
		useState(false);
	const searchKeyword = useDebounce(keyword);

	useEffect(() => {
		if (allMantrasData.length) {
			setFinalMantras(allMantrasData);
		}
	}, [allMantrasData]);

	useEffect(() => {
		if (searchKeyword.length > 1) {
			let data: searchResultsTypes[] = [];
			allMantrasData.forEach((mantra) => {
				if (
					mantra?.title
						.toLocaleLowerCase()
						.includes(
							searchKeyword.toLocaleLowerCase(),
						)
				) {
					data.push({
						image: mantra?.cover_image?.url || "",
						text: mantra?.title || "",
						url: `/dashboard/mantra/${mantra?.id}`,
						isKeyword: false,
					});
				}
			});
			setSearchResults(data);
			setShowResults(true);
		} else {
			setSearchResults([]);
			setShowResults(false);
		}
	}, [allMantrasData, searchKeyword]);

	return (
		<LayoutContainer ref={ref}>
			<Box
				paddingTop={isTablet ? 15 : 50}
				paddingBottom={120}
			>
				<Wrapper type="fluid">
					{/* Heading */}
					<div
						style={{
							display: isSmall ? "block" : "flex",
							alignItems: "center",
						}}
					>
						<H3>Mantra</H3>
						<ShowIf value={!isTablet}>
							<Spacing
								type={isSmall ? "s24" : "s64"}
							/>
							<SearchBox
								placeholder="Search"
								searchResults={searchResults}
								value={keyword}
								onTextChange={(text) => {
									setKeyword(text);
								}}
								autoFocus={true}
								showResults={showResults}
								onSearchResultsCloseRequest={() => {
									console.log("search close");
									setShowResults(false);
								}}
								backgroundColor="wildSand"
							/>
						</ShowIf>
					</div>
					<Spacing type="s24" />

					{/* Reminders */}
					<div
						style={{
							display: "grid",
							gridTemplateColumns: isSmall
								? "1fr"
								: "repeat(3, 1fr)",
							gridGap: "10px",
						}}
					>
						<Reminder>
							<div>
								<IconTick color="mantra" />
							</div>
							<Body2>
								Journal daily for 5 mins. Likho,
								&amp; let go.
							</Body2>
						</Reminder>
						<Reminder>
							<div>
								<IconTick color="mantra" />
							</div>
							<Body2>
								Refuel your mind with these
								mantras, skip social media.
							</Body2>
						</Reminder>
						<Reminder>
							<div>
								<IconTick color="mantra" />
							</div>
							<Body2>
								Want more life mantras? Ask and
								you shall receive.
							</Body2>
						</Reminder>
					</div>
					<Spacing type="s24" />

					{/* Banner */}
					<CardContainer
						padding={"0px"}
						background={`url(${bannerImg}) no-repeat`}
						height={"143px"}
						style={{
							backgroundPosition: "center center",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<Link to="/dashboard/journal">
							<BannerTextContainer>
								<Subtitle1 fontColor="white">
									Journal Prompts
								</Subtitle1>
							</BannerTextContainer>
						</Link>
					</CardContainer>
					<Spacing type="s24" />

					{/* Loading */}
					<ShowIf value={allMantras.loading}>
						<Loader>
							<Body1>Loading Mantras..</Body1>
						</Loader>
					</ShowIf>

					{/* Listing */}
					<ShowIf
						value={
							!allMantras.loading &&
							allMantrasData.length
						}
					>
						<Box>
							<StyledListing isSmall={isSmall}>
								{finalMantras?.map(
									(mantra, index) => {
										const isVideo =
											!!mantra?.vimeo_url;
										console.log(mantra?.content);
										const isText =
											!!mantra?.content &&
											mantra?.content !==
												"<p><br></p>";
										console.log(isText);
										return (
											<React.Fragment key={index}>
												<Box>
													<Link
														to={`/dashboard/mantra/${mantra?.id}`}
														title={`${mantra?.title} by ${mantra?.collaborator?.name}`}
													>
														<GeneralItemCard
															pageType="mantra"
															title={
																mantra?.title
															}
															description={
																mantra?.short_description
															}
															readingTime={
																mantra?.reading_time ||
																undefined
															}
															author={
																mantra
																	?.collaborator
																	?.name
															}
															imageUrl={
																mantra
																	?.cover_image
																	?.url
															}
															isVideo={isVideo}
															isText={isText}
														/>
													</Link>
												</Box>
											</React.Fragment>
										);
									},
								)}
							</StyledListing>
						</Box>
					</ShowIf>
				</Wrapper>
			</Box>
		</LayoutContainer>
	);
};

export default MantraListing;
