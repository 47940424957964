import {
	Body1,
	Box,
	FlexCenter,
	H3,
	Spacing,
	Wrapper,
} from "@workshore/nyaari-ds";
import {
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";

import {
	FoodIndex,
	Maybe,
	UploadFile,
	useGetFoodIndexListingQuery,
} from "../../hooks/graphql/graphql";
import useDebounce from "../../hooks/useDebounce/useDebounce";
import { useElementWidth } from "../../hooks/useElementSize/useElementSize";
import useIsTablet from "../../hooks/useIsTablet/useIsTablet";
import LayoutContainer from "../LayoutContainer/LayoutContainer";
import Loader from "../Loader/Loader";
import MealsHeader from "../DashboardSubHeader/DashboardSubHeader";
import SearchBox, {
	searchResultsTypes,
} from "../SearchBox/SearchBox";
import ShowIf from "../ShowIf/ShowIf";
import useIsMobile from "../../hooks/useIsMobile/useIsMobile";

import DashboardItemCard from "../DashboardItemCard/DashboardItemCard";
import { Link } from "react-router-dom";

type foodIndexType = Maybe<
	Array<
		Maybe<
			{ __typename?: "FoodIndex" } & Pick<
				FoodIndex,
				"id" | "name"
			> & {
					image?: Maybe<
						{ __typename?: "UploadFile" } & Pick<
							UploadFile,
							"url"
						>
					>;
				}
		>
	>
>;

const FoodIndexListing = () => {
	const [filterKeyword, setFilterKeyword] =
		useState("");

	const [showChildren, setShowChildren] =
		useState(false);
	const [keyword, setKeyword] = useState("");

	const ref = useRef<HTMLDivElement>(null);
	const isTablet = useIsTablet();
	const width = useElementWidth(ref);
	const isMobile = useIsMobile();
	const isSmall = width <= 750;
	const [showResults, setShowResults] =
		useState(false);
	const [searchResults, setSearchResults] =
		useState<searchResultsTypes[]>([]);
	const searchKeyword = useDebounce(keyword);
	const allFoodIndex =
		useGetFoodIndexListingQuery();
	const [finalListing, setFinalListing] =
		useState<foodIndexType>([]);
	const allFoodIndexData = useMemo(
		() => allFoodIndex.data?.foodIndices || [],
		[allFoodIndex],
	);
	useEffect(() => {
		if (allFoodIndexData) {
			setFinalListing(allFoodIndexData);
		}
	}, [allFoodIndexData]);
	useEffect(() => {
		if (searchKeyword.length > 1) {
			let data: searchResultsTypes[] = [];
			if (filterKeyword.length === 0) {
				allFoodIndexData.forEach((food) => {
					if (
						food?.name
							.toLocaleLowerCase()
							.includes(
								searchKeyword.toLocaleLowerCase(),
							)
					) {
						data.push({
							image: food.image?.url || "",
							text: food?.name || "",
							url: `/dashboard/meals/food-index/${food?.id}`,
							isKeyword: false,
						});
					}
				});
				setSearchResults(data);
				setShowResults(true);
			}
		} else {
			setShowResults(false);
			setSearchResults([]);
			setFilterKeyword("");
		}
	}, [
		searchKeyword,
		filterKeyword,
		allFoodIndexData,
	]);
	useEffect(() => {
		let foods = allFoodIndexData;

		if (filterKeyword) {
			foods = foods?.filter((d) => {
				return d?.name.includes(filterKeyword);
			});
			if (foods) {
				setKeyword(filterKeyword);
			}
		}

		setFinalListing(foods);
	}, [filterKeyword, allFoodIndexData]);

	const gridSize =
		width < 890 ? (width < 710 ? 2 : 3) : 4;

	return (
		<LayoutContainer
			ref={ref}
			backgroundColor="wildSand"
		>
			<ShowIf value={isTablet}>
				<MealsHeader
					autoHide={showChildren}
					onChildrenStateChange={(show) => {
						setShowChildren(!show);
					}}
				>
					<SearchBox
						placeholder="Enter Keywords"
						searchResults={searchResults}
						value={keyword}
						onTextChange={(text) => {
							setKeyword(text);
						}}
						onKeywordSelect={(keyword) => {
							setFilterKeyword(keyword);
						}}
						autoFocus={true}
						showResults={showResults}
						onSearchResultsCloseRequest={() => {
							console.log("search close");
							setShowResults(false);
						}}
					/>
				</MealsHeader>
			</ShowIf>
			<Box
				paddingTop={isTablet ? 15 : 50}
				paddingBottom={90}
			>
				<Wrapper type="fluid">
					<div
						style={{
							display: isSmall ? "block" : "flex",
							alignItems: "center",
						}}
					>
						<H3>Meals {">"} Food Index</H3>
						<ShowIf value={!isTablet}>
							<Spacing
								type={isSmall ? "s24" : "s64"}
							/>
							<SearchBox
								placeholder="Enter Keywords"
								searchResults={searchResults}
								value={keyword}
								onTextChange={(text) => {
									setKeyword(text);
									setFilterKeyword("");
								}}
								onKeywordSelect={(keyword) => {
									setFilterKeyword(keyword);
								}}
								showResults={showResults}
								onSearchResultsCloseRequest={() => {
									console.log("search close");
									setShowResults(false);
								}}
							/>
						</ShowIf>
					</div>
					<ShowIf value={allFoodIndex.loading}>
						<Loader>
							<Body1>
								Indexing Food Catalog..
							</Body1>
						</Loader>
					</ShowIf>
				</Wrapper>
				<ShowIf
					value={
						!allFoodIndex.loading &&
						allFoodIndex.data?.foodIndices?.length
					}
				>
					<Spacing type="s24" multiplier={1.5} />
					<Box
						marginLeft={
							isSmall && isMobile ? 20 : 50
						}
					>
						<div
							style={{
								display: "flex",
								flexWrap: "wrap",
							}}
						>
							{finalListing?.map(
								(food, index) => {
									const newWidth =
										width / gridSize -
										((isSmall && isMobile
											? 20
											: 50) +
											(4 - (gridSize - 1)) * 5);

									return (
										<div key={index}>
											<Box
												marginRight={
													isSmall ? 25 : 40
												}
											>
												<Link
													to={`/dashboard/meals/food-index/${food?.id}`}
													title={`${food?.name}`}
												>
													<DashboardItemCard
														height={
															isMobile ? 200 : 260
														}
														width={newWidth}
														imageUrl={
															food?.image?.url ||
															""
														}
														imageHeight={
															isMobile ? 155 : 200
														}
													>
														<Box
															margin="5px 12px"
															style={{
																flex: 1,
																display: "flex",
																flexDirection:
																	"column",
															}}
														>
															<Body1
																fontWeight={600}
																title={food?.name}
																style={{
																	textOverflow:
																		"ellipsis",
																	overflow:
																		"hidden",
																	whiteSpace:
																		"nowrap",
																}}
															>
																{food?.name}
															</Body1>
														</Box>
													</DashboardItemCard>
												</Link>
											</Box>
											<Spacing
												type="s24"
												multiplier={
													isMobile ? 1.1 : 1.7
												}
											/>
										</div>
									);
								},
							)}
						</div>
					</Box>
					{finalListing &&
					finalListing.length === 0 ? (
						<Wrapper>
							<FlexCenter>
								<Body1>No Food Index Found</Body1>
							</FlexCenter>
						</Wrapper>
					) : null}
				</ShowIf>
			</Box>
		</LayoutContainer>
	);
};

export default FoodIndexListing;
