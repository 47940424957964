import {
	Body2,
	Box,
	DS,
	ListItem,
	Spacing,
	useOutsideClick,
} from "@workshore/nyaari-ds";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import IconSearch from "../Icons/IconSearch";
import ShowIf from "../ShowIf/ShowIf";

const SearchInputContainer = styled.div<{
	backgroundColor?: keyof typeof DS.colors;
}>`
	flex: 1;
	${({ backgroundColor = "white" }) => `
	background-color: ${DS.colors[backgroundColor]};
	`}
	display: flex;
	height: 50px;
	border-radius: ${DS.borderRadius.body1}px;
	position: relative;
`;

const SearchInput = styled.input`
	outline: none;
	border: none;
	height: 50px;
	flex: 1;
	margin-left: ${DS.spacing.s24}px;
	margin-right: ${DS.spacing.s24}px;
	background-color: transparent;
	padding: 0px;
	color: ${DS.colors.mineShaft};
	font-size: ${DS.fontSize.body2}px;
	border-radius: ${DS.borderRadius.body1}px;
	font-family: ${DS.fontFamilies.primary};
`;

export type searchResultsTypes = {
	url: string;
	text: string;
	image: string;
	isKeyword?: boolean;
};

const ResultContainer = styled.div`
	position: absolute;
	width: 100%;
	top: 50px;
	background-color: ${DS.colors.white};
	border-radius: ${DS.borderRadius.body1}px;
	box-shadow: 0px 9px 11px rgba(0, 0, 0, 0.1);
`;

const InputWrapper = styled.div`
	padding-right: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	width: 100%;
	& * {
		flex-shrink: 0;
	}
`;

const StyledLink = styled(Link)`
	display: block;
`;

const SearchBox: FC<{
	placeholder?: string;
	loading?: boolean;
	autoFocus?: boolean;
	searchResults?: searchResultsTypes[];
	showResults?: boolean;
	onTextChange?: (text: string) => void;
	onSearchResultsCloseRequest?: () => void;
	onKeywordSelect?: (keyword: string) => void;
	value?: string;
	backgroundColor?: keyof typeof DS.colors;
}> = ({
	autoFocus = false,
	placeholder = "Search",
	loading = false,
	searchResults = [],
	showResults = false,
	onTextChange = () => {},
	value = "",
	onSearchResultsCloseRequest = () => {},
	onKeywordSelect = () => {},
	backgroundColor = "white",
}) => {
	const ref = useOutsideClick(
		onSearchResultsCloseRequest,
	);
	return (
		<SearchInputContainer
			backgroundColor={backgroundColor}
			ref={ref}
		>
			<InputWrapper>
				<Spacing type="s24" />
				<IconSearch />
				<SearchInput
					autoFocus={autoFocus}
					placeholder={placeholder}
					value={value}
					onChange={(e) =>
						onTextChange(e.target.value)
					}
				/>
			</InputWrapper>
			<ShowIf value={showResults}>
				<ResultContainer>
					<ShowIf value={loading}>
						<Box margin={DS.spacing.s16}>
							testing
						</Box>
					</ShowIf>
					<ShowIf
						value={
							searchResults.length && !loading
						}
					>
						<Box
							paddingLeft={DS.spacing.s16}
							paddingRight={DS.spacing.s16}
						>
							{searchResults.map(
								(result, index) => {
									return (
										<StyledLink
											to={result.url}
											key={index}
											onClick={(e) => {
												if (result.isKeyword) {
													e.preventDefault();
													onKeywordSelect(
														result.text,
													);
													onSearchResultsCloseRequest();
												}
											}}
										>
											<div>
												<Box
													margin={
														result.isKeyword
															? "14px 0px"
															: "9px 0px"
													}
													style={{
														cursor: "pointer",
													}}
												>
													<ListItem
														leftIcon={
															result.isKeyword ? (
																<div
																	style={{
																		width: 30,
																		height: 30,
																		display:
																			"flex",
																		justifyContent:
																			"center",
																		alignItems:
																			"center",
																	}}
																>
																	<IconSearch />
																</div>
															) : (
																<img
																	src={result.image.replace(
																		"amazonaws.com/",
																		"amazonaws.com/small_",
																	)}
																	alt={
																		result.text
																	}
																	style={{
																		width: 30,
																		height: 30,
																		objectFit:
																			"cover",
																		borderRadius: 3,
																	}}
																/>
															)
														}
														title={
															<Body2
																style={{
																	textTransform:
																		"capitalize",
																}}
															>
																{result.text}
															</Body2>
														}
													/>
												</Box>

												<div
													style={{
														height: 1,
														backgroundColor:
															DS.colors.wildSand,
													}}
												/>
											</div>
										</StyledLink>
									);
								},
							)}
						</Box>
					</ShowIf>
					<ShowIf
						value={
							!searchResults.length && !loading
						}
					>
						<Box margin={DS.spacing.s16}>
							<Body2>No Result Found</Body2>
						</Box>
					</ShowIf>
				</ResultContainer>
			</ShowIf>
		</SearchInputContainer>
	);
};

export default SearchBox;
