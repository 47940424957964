import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import {
	Body1,
	Body2,
	CardContainer,
	DS,
	FlexCenter,
	H3,
	Spacing,
} from "@workshore/nyaari-ds";
import PageModal from "../PageModal/PageModal";
import { useGetMastiDetailQuery } from "../../hooks/graphql/graphql";
import ShowIf from "../ShowIf/ShowIf";
import Loader from "../Loader/Loader";
import IconClock from "../Icons/IconClock";
import IconUser from "../Icons/IconUser";
import useIsMobile from "../../hooks/useIsMobile/useIsMobile";
import styled from "styled-components";
import { useElementWidth } from "../../hooks/useElementSize/useElementSize";
import HTMLParser from "../HTMLParser";
import VideoPlayer1 from "../VideoPlayer1/VideoPlayer1";

const Container = styled.div``;

const TopContainer = styled.div<{
	isMobile: boolean;
}>`
	display: flex;
	${({ isMobile }) => `
		padding: ${isMobile ? "0" : "20px 30px"};
		flex-direction: ${
			isMobile ? "column-reverse" : "column"
		};
	`}
`;

const Header = styled.div<{ isMobile: boolean }>`
	position: relative;
	display: grid;
	grid-gap: 10px;
	background-color: ${DS.colors.white};
	${({ isMobile }) => `
		grid-template-columns: ${
			isMobile ? "1fr" : "2fr 1fr"
		};	
		padding-inline: ${
			isMobile ? DS.spacing.s24 + "px" : "0"
		};
		padding-block:  ${
			isMobile
				? DS.spacing.s24 + "px"
				: "10px 30px"
		};
		${
			isMobile
				? `&::after {
					content: "";
					position: absolute;
					bottom: 0;
					left: ${DS.spacing.s24 + "px"};
					height: 2px;
					width: calc(100% - (${
						DS.spacing.s24 + "px"
					} * 2));
					border-radius: 4px;
					background-color: ${DS.colors.masti};
				};
				transform: translateY(-6px);
				box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.3);
				border-radius: 5px;
				`
				: null
		}
	`};
`;

const MetaInfo = styled(FlexCenter)`
	background-color: ${DS.colors.wildSand};
	border-radius: 4px;
	padding: 0.2em 0.4em;

	&:not(:last-child) {
		margin-right: 10px;
	}
`;

const ContentWrapper = styled.div<{
	isMobile: boolean;
}>`
	${({ isMobile }) => `padding: 5% ${
		isMobile ? "20px" : "10%"
	};
	`};

	img {
		width: 100%;
	}
`;

const MastiDetails = () => {
	const ref = useRef<HTMLDivElement>(null);
	const isMobile = useIsMobile();
	const elementWidth = useElementWidth(ref);
	const isSmall = elementWidth <= 750;
	const { id } = useParams<{ id: string }>();
	const { data: mastiDetail, loading } =
		useGetMastiDetailQuery({
			variables: {
				id,
			},
		});
	return (
		<PageModal>
			{/* Loading */}
			<ShowIf value={loading}>
				<Loader>
					<Body1>Loading Masti..</Body1>
				</Loader>
			</ShowIf>

			<ShowIf value={!!mastiDetail}>
				<Container ref={ref}>
					<TopContainer isMobile={isMobile}>
						{/* Header */}
						<Header isMobile={isMobile}>
							<H3>{mastiDetail?.masti?.title}</H3>
							<div
								style={{
									display: "flex",
									alignItems: "flex-start",
									justifyContent: isMobile
										? "flex-start"
										: "flex-end",
								}}
							>
								{mastiDetail?.masti
									?.reading_time ? (
									<MetaInfo>
										<IconClock color="masti" />
										<Spacing type="s4" />
										<Body2 fontWeight={500}>
											{
												mastiDetail?.masti
													?.reading_time
											}
										</Body2>
									</MetaInfo>
								) : null}
								{mastiDetail?.masti?.collaborator
									?.name ? (
									<MetaInfo>
										<IconUser color="masti" />
										<Spacing type="s4" />
										<Body2 fontWeight={500}>
											By{" "}
											{
												mastiDetail?.masti
													?.collaborator?.name
											}
										</Body2>
									</MetaInfo>
								) : null}
							</div>
						</Header>

						{/* Banner */}
						{mastiDetail?.masti?.vimeo_url ? (
							<VideoPlayer1
								url={
									mastiDetail?.masti?.vimeo_url
								}
							/>
						) : (
							<CardContainer
								padding={"0px"}
								background={`url(${mastiDetail?.masti?.cover_image?.url}) no-repeat`}
								height={
									isSmall ? "260px" : "569px"
								}
								style={{
									backgroundPosition:
										"center center",
									backgroundSize: "cover",
								}}
							></CardContainer>
						)}
					</TopContainer>

					{/* Content */}
					<ContentWrapper isMobile={isMobile}>
						<HTMLParser
							data={
								mastiDetail?.masti?.content || ""
							}
						/>
					</ContentWrapper>
				</Container>
			</ShowIf>
		</PageModal>
	);
};

export default MastiDetails;
