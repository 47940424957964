import {
	Body1,
	Box,
	Button,
	DS,
	FlexBetween,
	IconBack,
	Logo,
	Navbar,
	Spacing,
} from "@workshore/nyaari-ds";
import React, { FC, useEffect } from "react";
import styled from "styled-components";
import useIsTablet from "../../hooks/useIsTablet/useIsTablet";
import useRoute from "../../hooks/useRoute/useRoute";
import IconClose from "../Icons/IconClose";

let currentStack = 0;

const PageModalContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: ${96 + currentStack};
	overflow-x: hidden;
	overflow-y: auto;
	overflow-y: overlay;

	@media screen and (max-width: ${DS
			.customBreakpoints.drawerBreakpoint}px) {
		background-color: #fff;
	}
`;

const PageModalHeaderContainer = styled(Box)`
	background-color: #fff;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09);
	height: 100px;
	position: sticky;
	top: 0;
	padding-left: 50px;
	padding-right: 50px;
	display: flex;
	align-items: center;
	z-index: 1;
`;

export const PageModalHeader: FC<{
	rightActionType: "done" | "none";
	rightActionText: string;

	onRightAction: () => void;
}> = ({
	rightActionType,
	onRightAction,
	rightActionText,
}) => {
	const { goBack } = useRoute();
	const isTablet = useIsTablet();

	if (isTablet) {
		return (
			<Navbar
				leftIcon={
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
						onClick={() => {
							goBack();
						}}
					>
						<IconBack />
					</div>
				}
				mobileBreakpoint={
					DS.customBreakpoints.drawerBreakpoint
				}
				rightIcon={
					<>
						{rightActionType === "done" ? (
							<Body1
								fontColor="redRibbon"
								onClick={onRightAction}
							>
								{rightActionText}
							</Body1>
						) : null}
					</>
				}
			/>
		);
	}

	return (
		<PageModalHeaderContainer>
			<FlexBetween
				style={{
					width: "100%",
					alignItems: "center",
				}}
			>
				<Logo />
				<div
					style={{
						display: "flex",
						alignItems: "center",
					}}
				>
					{rightActionType === "done" ? (
						<Button
							handleButtonClick={onRightAction}
						>
							{rightActionText}
						</Button>
					) : null}
					<Spacing type="s24" />
					<div
						style={{
							cursor: "pointer",
						}}
						onClick={goBack}
					>
						<IconClose />
					</div>
				</div>
			</FlexBetween>
		</PageModalHeaderContainer>
	);
};

const PageModalBodyContainer = styled.div`
	background-color: #fff;
	margin: 0px auto;
	width: 100%;
	max-width: 1219px;
	min-height: calc(100vh - 100px);
	@media screen and (max-width: ${DS
			.customBreakpoints.drawerBreakpoint}px) {
		margin: 0px;
	}
`;

export const PageModalBody: FC = ({
	children,
}) => {
	return (
		<PageModalBodyContainer>
			{children}
		</PageModalBodyContainer>
	);
};

const PageModal: FC<{
	rightActionText?: string;
	rightActionType?: "done" | "none";
	onRightAction?: () => void;
}> = ({
	children,
	rightActionText = "Done",
	rightActionType = "none",
	onRightAction = () => {},
}) => {
	useEffect(() => {
		currentStack++;
		return () => {
			currentStack--;
		};
	}, []);
	return (
		<PageModalContainer>
			<PageModalHeader
				rightActionType={rightActionType}
				rightActionText={rightActionText}
				onRightAction={onRightAction}
			/>
			<PageModalBody>{children}</PageModalBody>
			<Spacing type="s64" />
		</PageModalContainer>
	);
};

export default PageModal;
