import React from "react";

const IconAdd = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.134"
      height="18.134"
      viewBox="0 0 18.134 18.134"
    >
      <path
        id="Icon_ionic-ios-add"
        data-name="Icon ionic-ios-add"
        d="M25.032,16.465H18.6V10.032a1.067,1.067,0,1,0-2.133,0v6.434H10.032a1.067,1.067,0,0,0,0,2.133h6.434v6.434a1.067,1.067,0,1,0,2.133,0V18.6h6.434a1.067,1.067,0,1,0,0-2.133Z"
        transform="translate(-8.465 -8.465)"
        fill="#83c59d"
        stroke="#83c59d"
        strokeWidth="1"
      />
    </svg>
  );
};

export default IconAdd;
