import {
	Body1,
	Body2,
	Box,
	Button,
	DS,
	FlexCenter,
	H3,
	Spacing,
	Table,
	Tabs,
	Wrapper,
} from "@workshore/nyaari-ds";
import React, { useRef, useState } from "react";
import {
	Redirect,
	useParams,
} from "react-router-dom";

import { useGetFoodIndexDetailsQuery } from "../../hooks/graphql/graphql";
import { useElementWidth } from "../../hooks/useElementSize/useElementSize";
import useIsTablet from "../../hooks/useIsTablet/useIsTablet";

import ImageSlider from "../ImageSlider/ImageSlider";
import Loader from "../Loader/Loader";
import PageModal from "../PageModal/PageModal";
import Seperator from "../Seperator/Seperator";
import ShowIf from "../ShowIf/ShowIf";

const isActive = (
	index: number,
	currentIndex: number,
) => {
	return index === currentIndex;
};

const FoodIndexDetails = () => {
	const [activeTab, setActiveTab] = useState(0);
	const isTablet = useIsTablet();
	const ref = useRef<HTMLDivElement>(null);
	const width = useElementWidth(ref);

	const isSmall = width < 730 || isTablet;
	const { id } = useParams<{ id: string }>();
	const { data, loading, error, refetch } =
		useGetFoodIndexDetailsQuery({
			variables: {
				id,
			},
		});

	const foodIndex = data?.foodIndex;
	const _activeTab = isSmall
		? activeTab
		: Math.min(activeTab, 3);

	if (id)
		return (
			<PageModal>
				<ShowIf value={loading}>
					<Loader>
						<Body2>Loading Details...</Body2>
					</Loader>
				</ShowIf>
				<ShowIf value={foodIndex && !loading}>
					<ShowIf value={isSmall}>
						<Box
							style={{
								width: "100%",

								backgroundColor: "#000",
							}}
						>
							<ImageSlider
								isSmall={isSmall}
								width="100%"
								height="50vw"
								images={[
									data?.foodIndex?.image?.url ||
										"",
								]}
							/>
						</Box>
						<div
							style={{
								height: 5,
								width: "100%",
								borderRadius: "5px 5px 0px 0px",
								backgroundColor: "#fff",
								marginTop: -5,
								zIndex: 1,
								position: "relative",
							}}
						/>
					</ShowIf>
					<Wrapper type="fluid" ref={ref}>
						<Box
							padding={
								isSmall
									? "14px 0px 30px 0px"
									: "30px 0px"
							}
						>
							<H3>{foodIndex?.name}</H3>

							<Spacing type="s24" />
							<div
								style={{
									display: "flex",
								}}
							>
								<ShowIf value={!isSmall}>
									<Box
										marginRight={50}
										style={{
											width: "40%",
											maxWidth: 404,
										}}
									>
										<ImageSlider
											isSmall={isSmall}
											width="100%"
											images={[
												data?.foodIndex?.image
													?.url || "",
											]}
										/>
										<Spacing type="s32" />
										<Body1>Values</Body1>
										<Spacing type="s16" />
										<Table
											isSmall={isSmall}
											tabItems={(
												data?.foodIndex?.values ||
												[]
											)?.map((d) => {
												return {
													title: (
														<Body2
															fontWeight={500}
														>
															{d?.Title}
														</Body2>
													),
													value: (
														<Body2>
															{d?.Unit}
														</Body2>
													),
													subItems: (
														d?.Sub_Nutrition_facts ||
														[]
													).map((d) => {
														return {
															title: (
																<Body2>
																	{d?.Title}
																</Body2>
															),
															value: (
																<Body2>
																	{d?.Unit}
																</Body2>
															),
														};
													}),
												};
											})}
										/>
									</Box>
								</ShowIf>
								<Box
									style={{
										width: isSmall
											? "100%"
											: "calc(60% - 50px)",
									}}
								>
									<Tabs
										width="unset"
										offsetPadding={
											isSmall
												? DS.spacing.s8
												: DS.spacing.s24
										}
										activeTabIndex={_activeTab}
										onTabClick={(tabIndex) => {
											setActiveTab(tabIndex);
										}}
										tabs={[
											...(isSmall
												? [
														{
															title: (
																<div>
																	<Body1
																		fontColor={
																			isActive(
																				_activeTab,
																				0,
																			)
																				? "meals"
																				: "mineShaft"
																		}
																	>
																		Values
																	</Body1>
																	<Spacing type="s4" />
																</div>
															),
															view: (
																<div>
																	<Box
																		padding={
																			isSmall
																				? "20px 14px"
																				: "30px 24px"
																		}
																		style={{
																			backgroundColor:
																				DS.colors
																					.wildSand,
																			borderRadius:
																				DS
																					.borderRadius
																					.body1,
																		}}
																	>
																		<Table
																			isSmall={
																				isSmall
																			}
																			tabItems={(
																				data
																					?.foodIndex
																					?.values ||
																				[]
																			)?.map(
																				(d) => {
																					return {
																						title:
																							(
																								<Body2
																									fontWeight={
																										500
																									}
																								>
																									{
																										d?.Title
																									}
																								</Body2>
																							),
																						value:
																							(
																								<Body2>
																									{
																										d?.Unit
																									}
																								</Body2>
																							),
																						subItems:
																							(
																								d?.Sub_Nutrition_facts ||
																								[]
																							).map(
																								(
																									d,
																								) => {
																									return {
																										title:
																											(
																												<Body2>
																													{
																														d?.Title
																													}
																												</Body2>
																											),
																										value:
																											(
																												<Body2>
																													{
																														d?.Unit
																													}
																												</Body2>
																											),
																									};
																								},
																							),
																					};
																				},
																			)}
																		/>
																	</Box>
																</div>
															),
														},
												  ]
												: []),
											{
												title: (
													<div>
														<Body1
															fontColor={
																isActive(
																	_activeTab,
																	isSmall ? 1 : 0,
																)
																	? "meals"
																	: "mineShaft"
															}
														>
															Insights
														</Body1>
														<Spacing type="s4" />
													</div>
												),
												view: (
													<div>
														<ShowIf
															value={
																(
																	data?.foodIndex
																		?.insights
																		?.Nutritional_Insight ||
																	[]
																).length > 0
															}
														>
															<Box
																padding={
																	isSmall
																		? "20px 14px"
																		: "30px 24px"
																}
																style={{
																	backgroundColor:
																		DS.colors
																			.wildSand,
																	borderRadius:
																		DS
																			.borderRadius
																			.body1,
																}}
															>
																<Body1
																	fontWeight={500}
																>
																	Nutritional
																	Insight
																</Body1>
																<Spacing
																	type="s2"
																	multiplier={6}
																/>

																{(
																	data?.foodIndex
																		?.insights
																		?.Nutritional_Insight ||
																	[]
																)?.map(
																	(d, index) => {
																		return (
																			<div
																				key={
																					index
																				}
																				// style={{
																				// 	borderBottom:
																				// 		index ===
																				// 			0 ||
																				// 		index ===
																				// 			(
																				// 				data
																				// 					?.foodIndex
																				// 					?.insights
																				// 					?.Nutritional_Insight ||
																				// 				[]
																				// 			)
																				// 				.length -
																				// 				1
																				// 			? "unset"
																				// 			: "1px solid #E2E2E2",
																				// }}
																			>
																				<div
																					key={
																						index
																					}
																					style={{
																						display:
																							"flex",
																						alignItems:
																							"center",
																					}}
																				>
																					{/* <div
																						style={{
																							width: 7,
																							height: 7,
																							borderRadius: 2,
																							backgroundColor:
																								DS
																									.colors
																									.meals,
																							marginRight:
																								DS
																									.spacing
																									.s8,
																						}}
																					></div> */}
																					<Body2
																						fontWeight={
																							400
																						}
																					>
																						{
																							d?.Description
																						}
																					</Body2>
																				</div>
																				<Spacing
																					type="s8"
																					multiplier={
																						index ===
																						(
																							data
																								?.foodIndex
																								?.insights
																								?.Nutritional_Insight ||
																							[]
																						)
																							.length -
																							1
																							? 0
																							: isSmall
																							? 0.5
																							: 1
																					}
																				/>
																				{data
																					?.foodIndex
																					?.insights
																					?.Nutritional_Insight
																					?.length! -
																					1 !==
																					index && (
																					<Seperator />
																				)}
																				<Spacing
																					type="s8"
																					multiplier={
																						index ===
																						(
																							data
																								?.foodIndex
																								?.insights
																								?.Nutritional_Insight ||
																							[]
																						)
																							.length -
																							1
																							? 0
																							: isSmall
																							? 0.5
																							: 1
																					}
																				/>
																			</div>
																		);
																	},
																)}
															</Box>
															<Spacing type="s16" />
														</ShowIf>
														<ShowIf
															value={
																(
																	data?.foodIndex
																		?.insights
																		?.Ayurveda_Insight ||
																	[]
																).length > 0
															}
														>
															<Box
																padding={
																	isSmall
																		? "20px 14px"
																		: "30px 24px"
																}
																style={{
																	backgroundColor:
																		DS.colors
																			.wildSand,
																	borderRadius:
																		DS
																			.borderRadius
																			.body1,
																}}
															>
																<Body1
																	fontWeight={500}
																>
																	Ayurveda Insight
																</Body1>
																<Spacing
																	type="s2"
																	multiplier={6}
																/>

																{(
																	data?.foodIndex
																		?.insights
																		?.Ayurveda_Insight ||
																	[]
																)?.map(
																	(d, index) => {
																		return (
																			<div
																				key={
																					index
																				}
																				// style={{
																				// 	borderBottom:
																				// 		index ===
																				// 			0 ||
																				// 		index ===
																				// 			(
																				// 				data
																				// 					?.foodIndex
																				// 					?.insights
																				// 					?.Ayurveda_Insight ||
																				// 				[]
																				// 			)
																				// 				.length -
																				// 				1
																				// 			? "unset"
																				// 			: "1px solid #E2E2E2",
																				// }}
																			>
																				<div
																					key={
																						index
																					}
																					style={{
																						display:
																							"flex",
																						alignItems:
																							"center",
																					}}
																				>
																					{/* <div
																						style={{
																							width: 7,
																							height: 7,
																							borderRadius: 2,
																							backgroundColor:
																								DS
																									.colors
																									.meals,
																							marginRight:
																								DS
																									.spacing
																									.s8,
																						}}
																					></div> */}
																					<Body2
																						fontWeight={
																							400
																						}
																					>
																						{
																							d?.Description
																						}
																					</Body2>
																				</div>
																				<Spacing
																					type="s8"
																					multiplier={
																						index ===
																						(
																							data
																								?.foodIndex
																								?.insights
																								?.Ayurveda_Insight ||
																							[]
																						)
																							.length -
																							1
																							? 0
																							: isSmall
																							? 0.5
																							: 1
																					}
																				/>
																				{data
																					?.foodIndex
																					?.insights
																					?.Ayurveda_Insight
																					?.length! -
																					1 !==
																					index && (
																					<Seperator />
																				)}
																				<Spacing
																					type="s8"
																					multiplier={
																						index ===
																						(
																							data
																								?.foodIndex
																								?.insights
																								?.Ayurveda_Insight ||
																							[]
																						)
																							.length -
																							1
																							? 0
																							: isSmall
																							? 0.5
																							: 1
																					}
																				/>
																			</div>
																		);
																	},
																)}
															</Box>
														</ShowIf>
													</div>
												),
											},
										]}
									/>
								</Box>
							</div>
						</Box>
					</Wrapper>
				</ShowIf>
				<ShowIf value={error}>
					<FlexCenter>
						<Body1>Failed to load details</Body1>
					</FlexCenter>
					<Spacing type="s24" />
					<FlexCenter>
						<Button width="100px">
							<Body2
								as="span"
								fontColor="white"
								onClick={() => {
									refetch();
								}}
							>
								Retry
							</Body2>
						</Button>
					</FlexCenter>
				</ShowIf>
			</PageModal>
		);

	return (
		<Redirect to="/dashboard/meals/food-index" />
	);
};

export default FoodIndexDetails;
