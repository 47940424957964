import React, {
	useRef,
	useState,
	useEffect,
	useMemo,
} from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
	H3,
	Body1,
	Body2,
	Box,
	Wrapper,
	Spacing,
} from "@workshore/nyaari-ds";

import LayoutContainer from "../LayoutContainer/LayoutContainer";
import ShowIf from "../ShowIf/ShowIf";
import { Reminder } from "../Reminder";
import GeneralItemCard from "../GeneralItemCard";
import SearchBox, {
	searchResultsTypes,
} from "../SearchBox/SearchBox";

import useIsTablet from "../../hooks/useIsTablet/useIsTablet";
import { useElementWidth } from "../../hooks/useElementSize/useElementSize";
import { useGetAllMastisListingQuery } from "../../hooks/graphql/graphql";

import IconTick from "../Icons/IconTick";

import { mastiListingType } from "./type";
import Loader from "../Loader/Loader";
import useDebounce from "../../hooks/useDebounce/useDebounce";

const StyledListing = styled.div<{
	isSmall: boolean;
}>`
	display: grid;
	grid-gap: 30px;
	${({ isSmall }) => `
	grid-template-columns: ${
		isSmall
			? "1fr"
			: "repeat(auto-fit, minmax(530px, 1fr))"
	};
	`};
`;

const MastiListing = () => {
	const ref = useRef<HTMLDivElement>(null);
	const isTablet = useIsTablet();
	const elementWidth = useElementWidth(ref);
	const isSmall = elementWidth <= 750;
	const allMastis = useGetAllMastisListingQuery();
	const allMastisData = useMemo(
		() => allMastis.data?.mastis || [],
		[allMastis],
	);
	const [finalMastis, setFinalMastis] =
		useState<mastiListingType>([]);

	const [keyword, setKeyword] = useState("");
	const [searchResults, setSearchResults] =
		useState<searchResultsTypes[]>([]);
	const [showResults, setShowResults] =
		useState(false);
	const searchKeyword = useDebounce(keyword);

	useEffect(() => {
		if (allMastisData.length) {
			setFinalMastis(allMastisData);
		}
	}, [allMastisData]);

	useEffect(() => {
		if (searchKeyword.length > 1) {
			let data: searchResultsTypes[] = [];
			allMastisData.forEach((masti) => {
				if (
					masti?.title
						.toLocaleLowerCase()
						.includes(
							searchKeyword.toLocaleLowerCase(),
						)
				) {
					data.push({
						image: masti?.cover_image?.url || "",
						text: masti?.title || "",
						url: `/dashboard/masti/${masti?.id}`,
						isKeyword: false,
					});
				}
			});
			setSearchResults(data);
			setShowResults(true);
		} else {
			setSearchResults([]);
			setShowResults(false);
		}
	}, [allMastisData, searchKeyword]);

	return (
		<LayoutContainer ref={ref}>
			<Box
				paddingTop={isTablet ? 15 : 50}
				paddingBottom={120}
			>
				<Wrapper type="fluid">
					{/* Heading */}
					<div
						style={{
							display: isSmall ? "block" : "flex",
							alignItems: "center",
						}}
					>
						<H3>Masti</H3>
						<ShowIf value={!isTablet}>
							<Spacing
								type={isSmall ? "s24" : "s64"}
							/>
							<SearchBox
								placeholder="Search"
								searchResults={searchResults}
								value={keyword}
								onTextChange={(text) => {
									setKeyword(text);
								}}
								autoFocus={true}
								showResults={showResults}
								onSearchResultsCloseRequest={() => {
									console.log("search close");
									setShowResults(false);
								}}
								backgroundColor="wildSand"
							/>
						</ShowIf>
					</div>
					<Spacing type="s24" />

					{/* Reminders */}
					<div
						style={{
							display: "grid",
							gridTemplateColumns: "1fr",
							gridGap: "10px",
						}}
					>
						<Reminder>
							<div>
								<IconTick color="masti" />
							</div>
							<Body2>
								Time flies whether you’re having
								fun or not. The choice is yours.
								Give yourself the permission to do
								something fun, every day, without
								any end goal or expectation.
							</Body2>
						</Reminder>
					</div>
					<Spacing type="s24" />

					{/* Loading */}
					<ShowIf value={allMastis.loading}>
						<Loader>
							<Body1>Loading mastis..</Body1>
						</Loader>
					</ShowIf>

					{/* Listing */}
					<ShowIf
						value={
							!allMastis.loading &&
							allMastisData.length
						}
					>
						<Box>
							<StyledListing isSmall={isSmall}>
								{finalMastis?.map(
									(masti, index) => {
										const isVideo =
											!!masti?.vimeo_url;
										const isText =
											!!masti?.content &&
											masti?.content !==
												"<p><br></p>";
										return (
											<React.Fragment key={index}>
												<Box>
													<Link
														to={`/dashboard/masti/${masti?.id}`}
														title={`${masti?.title} by ${masti?.collaborator?.name}`}
													>
														<GeneralItemCard
															pageType="masti"
															title={masti?.title}
															description={
																masti?.short_description
															}
															readingTime={
																masti?.reading_time ||
																undefined
															}
															author={
																masti
																	?.collaborator
																	?.name
															}
															imageUrl={
																masti?.cover_image
																	?.url
															}
															isVideo={isVideo}
															isText={isText}
														/>
													</Link>
												</Box>
											</React.Fragment>
										);
									},
								)}
							</StyledListing>
						</Box>
					</ShowIf>
				</Wrapper>
			</Box>
		</LayoutContainer>
	);
};

export default MastiListing;
