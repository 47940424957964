import {
	Body1,
	Caption,
	DS,
	FlexCenter,
	Spacing,
} from "@workshore/nyaari-ds";
import React, { FC } from "react";
import styled from "styled-components";
import IconClock from "../Icons/IconClock";
import IconBook from "../Icons/IconBook";
import IconVideo from "../Icons/IconVideo";
import IconUser from "../Icons/IconUser";
import {
	IconsProps,
	GeneralCardProps,
} from "./type";
import useIsMobile from "../../hooks/useIsMobile/useIsMobile";

const IMAGE_HEIGHT = "150px";
const ItemCardContainer = styled.div`
	border-radius: 5px;
	background-color: ${DS.colors.white};
	overflow: hidden;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: column;
	position: relative;
`;

const ItemCardImg = styled.div<{
	url: string | undefined;
	pageType?: string | undefined;
}>`
	background-color: ${DS.colors.lightGray};
	${({ url }) => `
    background:url(${url});
  `}
	width: 180px;
	height: ${IMAGE_HEIGHT};
	flex-shrink: 0;
	background-size: cover;
	background-position: center;

	${({ pageType }) =>
		pageType === "mantra" &&
		`
		@media screen and (max-width: 480px) {
			width: 114px;
			height: 110px;
		}
	`}

	${({ pageType }) =>
		pageType === "masti" &&
		`
		@media screen and (max-width: 480px) {
			width: 114px;
			height: 110px;
		}
	`}
`;

const ItemCardHeader = styled.div`
	display: flex;
	background: ${DS.colors.wildSand};
`;

const ItemCardText = styled.div`
	padding: 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 1 1 100%;
`;

const ItemCardBody = styled.div<{
	isMobile: boolean;
}>`
	padding-block: 10px;
	height: 100px;
	overflow: hidden;
	${({ isMobile }) => `
		padding-inline: 10px ${isMobile ? "20%" : "10px"};
	`}
`;

const StyledIcons = styled(FlexCenter)<{
	isMobile: boolean;
	pageType: IconsProps;
	isVideo: boolean;
	isText?: boolean;
}>`
	${({ isMobile, pageType, isVideo, isText }) => `
	svg:not(:last-child) {
		padding-right: ${
			isMobile ? "0" : DS.spacing.s16 + "px"
		};
	}
	${
		isMobile
			? `position: absolute;
				top: calc(${IMAGE_HEIGHT} + 15px);
				right: 15px;
				background-color: ${DS.colors[pageType]};
				display: grid;
				grid-template-rows: 1fr;
				grid-gap: 15px;
				border-radius: 6px;
				padding: 10px 0;

				@media screen and (max-width: 480px) {
					top: calc(120px);
				}

				svg {
					padding-inline: 10px;
				}

				${
					isText
						? `&:after{
					content: '';
					height: 1px;
					background-color: ${DS.colors.white};
					grid-row: 2;
				}`
						: null
				}

				${
					isVideo
						? `&:after{
					content: '';
					height: 1px;
					background-color: ${DS.colors.white};
					grid-row: 2;
				}`
						: null
				}
				`
			: null
	}
		
	`}
`;

const GeneralItemCard: FC<GeneralCardProps> = ({
	pageType = "mantra",
	title,
	description,
	readingTime,
	author,
	isVideo,
	imageUrl,
	isText,
}) => {
	const isMobile = useIsMobile();
	return (
		<ItemCardContainer>
			<ItemCardHeader>
				<ItemCardImg
					url={imageUrl}
					pageType={pageType}
				/>
				<ItemCardText>
					<Body1 fontWeight={600} title={title}>
						{title}
					</Body1>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
						}}
					>
						{readingTime ? (
							<FlexCenter>
								<IconClock color={pageType} />
								<Spacing type="s4" />
								<Caption fontWeight={500}>
									{readingTime}
								</Caption>
								<Spacing type="s8" />
							</FlexCenter>
						) : null}
						{author ? (
							<FlexCenter>
								<IconUser color={pageType} />
								<Spacing type="s4" />
								<Caption fontWeight={500}>
									By {author}
								</Caption>
								<Spacing type="s8" />
							</FlexCenter>
						) : null}
						<StyledIcons
							isMobile={isMobile}
							pageType={pageType}
							isVideo={isVideo}
							isText={isText}
						>
							{isText ? (
								<IconBook
									color={
										isMobile ? "white" : pageType
									}
								/>
							) : null}
							{isVideo ? (
								<>
									<IconVideo
										color={
											isMobile
												? "white"
												: pageType
										}
									/>
								</>
							) : null}
						</StyledIcons>
					</div>
				</ItemCardText>
			</ItemCardHeader>
			<ItemCardBody isMobile={isMobile}>
				<Caption
					fontWeight={500}
					fontColor="boulder"
				>
					{description}...
				</Caption>
			</ItemCardBody>
		</ItemCardContainer>
	);
};

export default GeneralItemCard;
