import {
	Box,
	Button,
	H3,
	IconMantra,
	IconMasti,
	IconMeals,
	IconMeditation,
	IconMovement,
	Spacing,
	Subtitle1,
} from "@workshore/nyaari-ds";
import React, {
	useEffect,
	useRef,
	useState,
} from "react";
import {
	Link,
	Route,
	Switch,
} from "react-router-dom";
import styled from "styled-components";
import Lottie from "react-lottie-player";

// Components
import DashboardDrawer from "../../components/DashboardDrawer/DashboardDrawer";
import { DashboardBottomMenu } from "../../components/DashboardMenu/DashboardMenu";
import DashboardLanding from "../../components/DashboardLanding/DashboardLanding";
import Layout from "../../components/Layout/Layout";
import EditWhatWhy from "../../components/EditWhatWhy/EditWhatWhy";
import Subscription from "../../components/Subscription/Subscription";
import OpenSubscription from "../../components/OpenSubscription/OpenSubscription";
import ProfileEdit from "../../components/ProfileEdit/ProfileEdit";
import ViewPriorities from "../../components/ViewPriorities/ViewPriorities";
import EditPriorities from "../../components/EditPriorities/EditPriorities";
import DailyQuote from "../../components/DailyQuote/DailyQuote";

import loader from "../../assets/anim/loader.json";
import me from "../../services/me";

// Hooks
import { useMeQuery } from "../../hooks/graphql/graphql";
import useRoute from "../../hooks/useRoute/useRoute";
import useSidebarStack from "../../hooks/useSidebarStack/useSidebarStack";
import useElementScroll, {
	elementScroll,
} from "../../hooks/useElementScroll/useElementScroll";
import { useSharedStateChange } from "@tinyd/usesharedstate";
import useIsTablet from "../../hooks/useIsTablet/useIsTablet";

// Utilities
import generateRandomNumber from "../../utils/getRandomNumber";
import getIsExpired from "../../utils/getIsExpired";

// Pages
import MealsPage from "../MealsPage/MealsPage";
import MantraPage from "../MantraPage/MantraPage";
import MovementPage from "../MovementPage/MovementPage";
import MastiPage from "../MastiPage/MastiPage";
import MeditationPage from "../MeditationPage/MeditationPage";

const loadingTexts = [
	"Fetching 🥗 Healthy Recipes",
	"Loading 🤸 Movements",
	"Reading 👌 Great Mantras",
	"Collecting 🙋🏼‍♀️ Masti",
	"Practicing 🧘 Meditation",
];

const DashboardContainer = styled.div<{
	isSmall: boolean;
}>`
	width: 100vw;
	${({ isSmall }) => `
	height: calc(100vh - ${isSmall ? 50 : 0}px);
  `}
	display: flex;
	overflow-x: hidden;
	justify-content: space-between;
`;

const DashboardPage = () => {
	const isTablet = useIsTablet();
	const ref = useRef<HTMLDivElement>(null);
	const scroll = useElementScroll(ref);
	const setScroll =
		useSharedStateChange<elementScroll>(
			"@scroll",
		);
	const { goTo, location } = useRoute();

	const [number, setLoadingNumber] = useState(
		generateRandomNumber(
			0,
			loadingTexts.length - 1,
		),
	);
	const { data, loading, error } = useMeQuery({
		fetchPolicy: "no-cache",
	});

	const [loaded, setLoaded] = useState(
		me.hasMe(),
	);

	useEffect(() => {
		if (data && !loading) {
			me.set({
				createdAt: data.newMe!.createdAt,
				email: data.newMe!.email,
				id: data.newMe!.id,
				name: data.newMe!.name,
				avatar: {
					id: (
						data.newMe!.avatar! || { id: null }
					).id,
					url: (
						data.newMe!.avatar! || { url: null }
					).url,
				},
				bio: data.newMe!.bio!,
				city: data.newMe!.city!,
				country: data.newMe!.country!,
				dob: data.newMe!.dob,
				state: data.newMe!.state!,
				timezone: data.newMe!.timezone!,
				what: data.newMe!.what!,
				why: data.newMe!.why!,
				trialDays: data.newMe!.trialDays!,
				subEnd: data.newMe!.subEnd!,
				subPlanId: data.newMe!.subPlanId!,
				subStatus: data.newMe!.subStatus!,
			});

			console.log(me.get());
			setTimeout(() => {
				setLoaded(true);
				if (
					getIsExpired(
						data.newMe!.createdAt!,
						data.newMe!.trialDays!,
						data.newMe!.subStatus! || "inactive",
					)
				) {
					goTo("/dashboard#sb_subscription");
				}
			}, 4000);
		}
		if (error) {
			goTo("/logout");
		}
	}, [
		loaded,
		data,
		loading,
		error,
		goTo,
		location,
	]);
	useEffect(() => {
		if (!loaded && typeof number === "number") {
			setTimeout(() => {
				setLoadingNumber(
					(number + 1) %
						(loadingTexts.length - 1),
				);
			}, 1200);
		}
	}, [loaded, number]);
	useSidebarStack();

	useEffect(() => {
		setScroll(scroll);
	}, [setScroll, scroll]);

	return (
		<>
			{loaded && (
				<>
					<DashboardContainer isSmall={isTablet}>
						<DashboardDrawer.Left />
						<Layout ref={ref}>
							<Switch>
								<Route path="/dashboard" exact>
									<DashboardLanding />
									<Spacing
										type="s64"
										multiplier={2}
									/>
								</Route>
								<Route path="/dashboard/meals">
									<MealsPage />
								</Route>
								<Route path="/dashboard/movement">
									<MovementPage />
								</Route>
								<Route path="/dashboard/meditation">
									<MeditationPage />
								</Route>
								<Route path="/dashboard/mantra">
									<MantraPage />
								</Route>
								<Route path="/dashboard/journal">
									<MantraPage />
								</Route>
								<Route path="/dashboard/masti">
									<MastiPage />
								</Route>
								<Route
									path="/dashboard/profile/whats-your-why"
									exact
								>
									<DashboardLanding />
									<EditWhatWhy />
									<Spacing
										type="s64"
										multiplier={2}
									/>
								</Route>
								<Route
									path="/dashboard/subscription"
									exact
								>
									<OpenSubscription />
								</Route>
								<Route
									path="/dashboard/subscription/success"
									exact
								>
									<div
										style={{
											height: "100vh",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											flexDirection: "column",
										}}
									>
										<H3>Thank You!</H3>
										<Subtitle1>
											Payment Successful!
										</Subtitle1>
										<Link to="/dashboard">
											<Button
												handleButtonClick={() => {}}
											>
												<Box padding="20px">
													Go To Dashboard
												</Box>
											</Button>
										</Link>
									</div>
								</Route>
								<Route
									path="/dashboard/subscription/failed"
									exact
								>
									<div
										style={{
											height: "100vh",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										<div
											style={{
												height: "100vh",
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												flexDirection: "column",
											}}
										>
											<H3>Sorry!</H3>
											<Subtitle1>
												Payment failed!
											</Subtitle1>
											<Link to="/dashboard">
												<Button
													handleButtonClick={() => {}}
												>
													Go To Dashboard
												</Button>
											</Link>
										</div>
									</div>
								</Route>
								<Route
									path="/dashboard/priorities"
									exact
								>
									<ViewPriorities />
								</Route>
								<Route path="/dashboard/priorities/select/:index">
									<EditPriorities />
								</Route>
								<Route>
									<div
										style={{
											height: "100vh",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										<H3>Coming Soon</H3>
									</div>
								</Route>
							</Switch>

							{/* Route less routes, hashed routes */}
							<Subscription />
							<ProfileEdit />
						</Layout>
						<DashboardDrawer.Right />
					</DashboardContainer>
					<DashboardBottomMenu
						items={[
							{
								text: "Meals",
								color: "mineShaft",
								activeColor: "meals",
								icon: <IconMeals />,
								activeIcon: <IconMeals active />,
								url: "/dashboard/meals",
							},
							{
								text: "Movement",
								color: "mineShaft",
								activeColor: "movement",
								icon: <IconMovement />,
								activeIcon: (
									<IconMovement active />
								),
								url: "/dashboard/movement",
							},
							{
								text: "Meditation",
								color: "mineShaft",
								activeColor: "meditation",
								icon: <IconMeditation />,
								activeIcon: (
									<IconMeditation active />
								),
								url: "/dashboard/meditation",
							},
							{
								text: "Mantra",
								color: "mineShaft",
								activeColor: "mantra",
								icon: <IconMantra />,
								activeIcon: <IconMantra active />,
								url: "/dashboard/mantra",
							},
							{
								text: "Masti",
								color: "mineShaft",
								activeColor: "masti",
								icon: <IconMasti />,
								activeIcon: <IconMasti active />,
								url: "/dashboard/masti",
							},
						]}
					/>
					<DailyQuote />
				</>
			)}
			{!loaded && (
				<>
					<div
						style={{
							height: "100vh",
							width: "100vw",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "column",
						}}
					>
						<Lottie
							loop
							animationData={loader}
							play
							speed={1.2}
							style={{ width: 250, height: 250 }}
						/>
						<Spacing type="s24" />
						<Subtitle1>
							{loadingTexts[number]}
							...
						</Subtitle1>
					</div>
				</>
			)}
		</>
	);
};

export default DashboardPage;
