import {
	Body1,
	Body2,
	Box,
	Button,
	DS,
	FlexCenter,
	H3,
	Spacing,
	Subtitle1,
	Table,
	Tabs,
	Tag,
	Wrapper,
} from "@workshore/nyaari-ds";
import React, { useRef, useState } from "react";
import {
	Redirect,
	useParams,
} from "react-router-dom";
import styled from "styled-components";
import { useGetRecipesQuery } from "../../hooks/graphql/graphql";
import { useElementWidth } from "../../hooks/useElementSize/useElementSize";
import useIsMobile from "../../hooks/useIsMobile/useIsMobile";
import useIsTablet from "../../hooks/useIsTablet/useIsTablet";
import IconClock from "../Icons/IconClock";
import IconFire from "../Icons/IconFire";
import IconServe from "../Icons/IconServe";
import IconUser from "../Icons/IconUser";
import ImageSlider from "../ImageSlider/ImageSlider";
import Loader from "../Loader/Loader";
import PageModal from "../PageModal/PageModal";
import Seperator from "../Seperator/Seperator";
import ShowIf from "../ShowIf/ShowIf";

const ScaleDown = styled.span<{ scale?: number }>`
	display: flex;
	justify-content: center;
	align-items: center;
	& > * {
		transform: scale(${({ scale = 1 }) => scale});
	}
`;

const isActive = (
	index: number,
	currentIndex: number,
) => {
	return index === currentIndex;
};

const RecipesDetails = () => {
	const [activeTab, setActiveTab] = useState(0);
	const isTablet = useIsTablet();
	const ref = useRef<HTMLDivElement>(null);
	const width = useElementWidth(ref);
	const isMobile = useIsMobile();

	const isSmall = width < 730 || isTablet;
	const { id } = useParams<{ id: string }>();
	const { data, loading, error, refetch } =
		useGetRecipesQuery({
			variables: {
				id,
			},
		});

	const recipe = data?.recipe;
	const _activeTab = isSmall
		? activeTab
		: Math.min(activeTab, 3);

	if (id)
		return (
			<PageModal>
				<ShowIf value={loading}>
					<Loader>
						<Body2>Loading Recipe...</Body2>
					</Loader>
				</ShowIf>
				<ShowIf value={recipe && !loading}>
					<ShowIf value={isSmall}>
						<Box
							style={{
								width: "100%",

								backgroundColor: "#000",
							}}
						>
							<ImageSlider
								isSmall={isSmall}
								width="100%"
								height="50vw"
								images={data?.recipe?.SlideShow?.images
									?.map((d) => d?.url || "")
									.filter((d) => d.length)}
							/>
						</Box>
						<div
							style={{
								height: 5,
								width: "100%",
								borderRadius: "5px 5px 0px 0px",
								backgroundColor: "#fff",
								marginTop: -5,
								zIndex: 1,
								position: "relative",
							}}
						/>
					</ShowIf>
					<Wrapper type="fluid" ref={ref}>
						<Box
							padding={
								isSmall
									? "14px 0px 30px 0px"
									: "30px 0px"
							}
						>
							<H3>{recipe?.Title}</H3>

							<div
								style={{
									display: "flex",
									flexWrap: "wrap",
								}}
							>
								<div>
									<Spacing
										type="s16"
										multiplier={
											isSmall ? 0.75 : 1
										}
									/>

									<Tag
										background={
											DS.colors.wildSand
										}
										paddingVertical={
											isSmall ? "4" : "8"
										}
									>
										<FlexCenter>
											<ScaleDown
												scale={isSmall ? 0.9 : 1}
											>
												<IconClock color="meals" />
											</ScaleDown>
											<Spacing
												multiplier={
													isSmall ? 0.75 : 1
												}
											/>
											<Body2>
												Prep :{" "}
												{
													data?.recipe
														?.Prep_Duration
														?.duration
												}{" "}
												{
													data?.recipe
														?.Prep_Duration?.unit
												}
											</Body2>
										</FlexCenter>
									</Tag>
								</div>
								<Spacing
									type="s16"
									multiplier={isSmall ? 0.75 : 1}
								/>
								<div>
									<Spacing
										type="s16"
										multiplier={
											isSmall ? 0.75 : 1
										}
									/>
									<Tag
										background={
											DS.colors.wildSand
										}
										paddingVertical={
											isSmall ? "4" : "8"
										}
									>
										<FlexCenter>
											<ScaleDown
												scale={isSmall ? 0.9 : 1}
											>
												<IconClock color="meals" />
											</ScaleDown>
											<Spacing
												multiplier={
													isSmall ? 0.75 : 1
												}
											/>
											<Body2>
												Cook :{" "}
												{
													data?.recipe
														?.Cook_Duration
														?.duration
												}{" "}
												{
													data?.recipe
														?.Cook_Duration?.unit
												}
											</Body2>
										</FlexCenter>
									</Tag>
								</div>
								<Spacing
									type="s16"
									multiplier={isSmall ? 0.75 : 1}
								/>
								<div>
									<Spacing
										type="s16"
										multiplier={
											isSmall ? 0.75 : 1
										}
									/>
									<Tag
										background={
											DS.colors.wildSand
										}
										paddingVertical={
											isSmall ? "4" : "8"
										}
									>
										<FlexCenter>
											<ScaleDown
												scale={isSmall ? 0.9 : 1}
											>
												<IconFire color="meals" />
											</ScaleDown>
											<Spacing
												multiplier={
													isSmall ? 0.75 : 1
												}
											/>
											<Body2>
												{data?.recipe?.Calories}
											</Body2>
										</FlexCenter>
									</Tag>
								</div>
								<Spacing
									type="s16"
									multiplier={isSmall ? 0.75 : 1}
								/>
								<div>
									<Spacing
										type="s16"
										multiplier={
											isSmall ? 0.75 : 1
										}
									/>
									<Tag
										background={
											DS.colors.wildSand
										}
										paddingVertical={
											isSmall ? "4" : "8"
										}
									>
										<FlexCenter>
											<ScaleDown
												scale={isSmall ? 0.8 : 1}
											>
												<IconServe />
											</ScaleDown>
											<Spacing
												multiplier={
													isSmall ? 0.75 : 1
												}
											/>
											<Body2>
												Serve
												{(data?.recipe
													?.No_Of_Servings || 0) >
												1
													? "s"
													: ""}{" "}
												{
													data?.recipe
														?.No_Of_Servings
												}
											</Body2>
										</FlexCenter>
									</Tag>
								</div>
								<Spacing
									type="s16"
									multiplier={isSmall ? 0.75 : 1}
								/>
								<div>
									<Spacing
										type="s16"
										multiplier={
											isSmall ? 0.75 : 1
										}
									/>
									<Tag
										background={
											DS.colors.wildSand
										}
										paddingVertical={
											isSmall ? "4" : "8"
										}
									>
										<FlexCenter>
											<ScaleDown
												scale={isSmall ? 0.9 : 1}
											>
												<IconUser />
											</ScaleDown>
											<Spacing
												multiplier={
													isSmall ? 0.75 : 1
												}
											/>
											<Body2>
												By{" "}
												{
													data?.recipe
														?.collaborator?.name
												}
											</Body2>
										</FlexCenter>
									</Tag>
								</div>
							</div>
							<Spacing type="s24" />
							<div
								style={{
									display: "flex",
								}}
							>
								<ShowIf value={!isSmall}>
									<Box
										marginRight={50}
										style={{
											width: "40%",
											maxWidth: 404,
										}}
									>
										<ImageSlider
											isSmall={isSmall}
											width="100%"
											images={data?.recipe?.SlideShow?.images
												?.map((d) => d?.url || "")
												.filter((d) => d.length)}
										/>
										<Spacing type="s32" />
										<Body1>Values</Body1>
										<Spacing type="s16" />
										<Table
											isSmall={isSmall}
											tabItems={(
												data?.recipe
													?.Nutrition_facts || []
											)?.map((d) => {
												return {
													title: (
														<Body2
															fontWeight={500}
														>
															{d?.Title}
														</Body2>
													),
													value: (
														<Body2>
															{d?.Unit}
														</Body2>
													),
													subItems: (
														d?.Sub_Nutrition_facts ||
														[]
													).map((d) => {
														return {
															title: (
																<Body2>
																	{d?.Title}
																</Body2>
															),
															value: (
																<Body2>
																	{d?.Unit}
																</Body2>
															),
														};
													}),
												};
											})}
										/>
									</Box>
								</ShowIf>
								<Box
									style={{
										width: isSmall
											? "100%"
											: "calc(60% - 50px)",
									}}
								>
									<Tabs
										width="unset"
										offsetPadding={
											isSmall
												? DS.spacing.s8
												: DS.spacing.s24
										}
										activeTabIndex={_activeTab}
										onTabClick={(tabIndex) => {
											setActiveTab(tabIndex);
										}}
										tabs={[
											...(isSmall
												? [
														{
															title: (
																<div>
																	<Body1
																		fontColor={
																			isActive(
																				_activeTab,
																				0,
																			)
																				? "meals"
																				: "mineShaft"
																		}
																	>
																		Values
																	</Body1>
																	<Spacing type="s4" />
																</div>
															),
															view: (
																<div>
																	<Box
																		padding={
																			isSmall
																				? "20px 14px"
																				: "30px 24px"
																		}
																		style={{
																			backgroundColor:
																				DS.colors
																					.wildSand,
																			borderRadius:
																				DS
																					.borderRadius
																					.body1,
																		}}
																	>
																		<Table
																			isSmall={
																				isSmall
																			}
																			tabItems={(
																				data
																					?.recipe
																					?.Nutrition_facts ||
																				[]
																			)?.map(
																				(d) => {
																					return {
																						title:
																							(
																								<Body2
																									fontWeight={
																										500
																									}
																								>
																									{
																										d?.Title
																									}
																								</Body2>
																							),
																						value:
																							(
																								<Body2>
																									{
																										d?.Unit
																									}
																								</Body2>
																							),
																						subItems:
																							(
																								d?.Sub_Nutrition_facts ||
																								[]
																							).map(
																								(
																									d,
																								) => {
																									return {
																										title:
																											(
																												<Body2>
																													{
																														d?.Title
																													}
																												</Body2>
																											),
																										value:
																											(
																												<Body2>
																													{
																														d?.Unit
																													}
																												</Body2>
																											),
																									};
																								},
																							),
																					};
																				},
																			)}
																		/>
																	</Box>
																</div>
															),
														},
												  ]
												: []),
											{
												title: (
													<div>
														<Body1
															fontColor={
																isActive(
																	_activeTab,
																	isSmall ? 1 : 0,
																)
																	? "meals"
																	: "mineShaft"
															}
														>
															Insights
														</Body1>
														<Spacing type="s4" />
													</div>
												),
												view: (
													<div>
														<ShowIf
															value={
																(
																	data?.recipe
																		?.Insights
																		?.Nutritional_Insight ||
																	[]
																).length > 0
															}
														>
															<Box
																padding={
																	isSmall
																		? "20px 14px"
																		: "30px 24px"
																}
																style={{
																	backgroundColor:
																		DS.colors
																			.wildSand,
																	borderRadius:
																		DS
																			.borderRadius
																			.body1,
																}}
															>
																<Body1
																	fontWeight={500}
																>
																	Nutritional
																	Insight
																</Body1>
																<Spacing
																	type="s2"
																	multiplier={6}
																/>

																{(
																	data?.recipe
																		?.Insights
																		?.Nutritional_Insight ||
																	[]
																)?.map(
																	(d, index) => {
																		return (
																			<div
																				key={
																					index
																				}
																				// style={{
																				// 	borderBottom:
																				// 		index ===
																				// 			0 ||
																				// 		index ===
																				// 			(
																				// 				data
																				// 					?.recipe
																				// 					?.Insights
																				// 					?.Nutritional_Insight ||
																				// 				[]
																				// 			)
																				// 				.length -
																				// 				1
																				// 			? "unset"
																				// 			: "1px solid #E2E2E2",
																				// }}
																			>
																				<div
																					key={
																						index
																					}
																					style={{
																						display:
																							"flex",
																						alignItems:
																							"center",
																					}}
																				>
																					{/* <div
																						style={{
																							width: 7,
																							height: 7,
																							borderRadius: 2,
																							backgroundColor:
																								DS
																									.colors
																									.meals,
																							marginRight:
																								DS
																									.spacing
																									.s8,
																						}}
																					></div> */}
																					<Body2
																						fontWeight={
																							400
																						}
																					>
																						{
																							d?.Description
																						}
																					</Body2>
																				</div>
																				<Spacing
																					type="s8"
																					multiplier={
																						index ===
																						(
																							data
																								?.recipe
																								?.Insights
																								?.Ayurveda_Insight ||
																							[]
																						)
																							.length -
																							1
																							? 0
																							: isSmall
																							? 0.5
																							: 1
																					}
																				/>
																				{data
																					?.recipe
																					?.Insights
																					?.Nutritional_Insight
																					?.length! -
																					1 !==
																					index && (
																					<Seperator />
																				)}
																				<Spacing
																					type="s8"
																					multiplier={
																						index ===
																						(
																							data
																								?.recipe
																								?.Insights
																								?.Ayurveda_Insight ||
																							[]
																						)
																							.length -
																							1
																							? 0
																							: isSmall
																							? 0.5
																							: 1
																					}
																				/>
																			</div>
																		);
																	},
																)}
															</Box>
															<Spacing type="s16" />
														</ShowIf>
														<ShowIf
															value={
																(
																	data?.recipe
																		?.Insights
																		?.Ayurveda_Insight ||
																	[]
																).length > 0
															}
														>
															<Box
																padding={
																	isSmall
																		? "20px 14px"
																		: "30px 24px"
																}
																style={{
																	backgroundColor:
																		DS.colors
																			.wildSand,
																	borderRadius:
																		DS
																			.borderRadius
																			.body1,
																}}
															>
																<Body1
																	fontWeight={500}
																>
																	Ayurveda Insight
																</Body1>
																<Spacing
																	type="s2"
																	multiplier={6}
																/>

																{(
																	data?.recipe
																		?.Insights
																		?.Ayurveda_Insight ||
																	[]
																)?.map(
																	(d, index) => {
																		return (
																			<div
																				key={
																					index
																				}
																				// style={{
																				// 	borderBottom:
																				// 		index ===
																				// 			0 ||
																				// 		index ===
																				// 			(
																				// 				data
																				// 					?.recipe
																				// 					?.Insights
																				// 					?.Ayurveda_Insight ||
																				// 				[]
																				// 			)
																				// 				.length -
																				// 				1
																				// 			? "unset"
																				// 			: "1px solid #E2E2E2",
																				// }}
																			>
																				<div
																					key={
																						index
																					}
																					style={{
																						display:
																							"flex",
																						alignItems:
																							"center",
																					}}
																				>
																					{/* <div
																						style={{
																							width: 7,
																							height: 7,
																							borderRadius: 2,
																							backgroundColor:
																								DS
																									.colors
																									.meals,
																							marginRight:
																								DS
																									.spacing
																									.s8,
																						}}
																					></div> */}
																					<Body2
																						fontWeight={
																							400
																						}
																					>
																						{
																							d?.Description
																						}
																					</Body2>
																				</div>
																				<Spacing
																					type="s8"
																					multiplier={
																						index ===
																						(
																							data
																								?.recipe
																								?.Insights
																								?.Ayurveda_Insight ||
																							[]
																						)
																							.length -
																							1
																							? 0
																							: isSmall
																							? 0.5
																							: 1
																					}
																				/>
																				{data
																					?.recipe
																					?.Insights
																					?.Ayurveda_Insight
																					?.length! -
																					1 !==
																					index && (
																					<Seperator />
																				)}
																				<Spacing
																					type="s8"
																					multiplier={
																						index ===
																						(
																							data
																								?.recipe
																								?.Insights
																								?.Ayurveda_Insight ||
																							[]
																						)
																							.length -
																							1
																							? 0
																							: isSmall
																							? 0.5
																							: 1
																					}
																				/>
																			</div>
																		);
																	},
																)}
															</Box>
														</ShowIf>
													</div>
												),
											},
											{
												title: (
													<div>
														<Body1
															fontColor={
																isActive(
																	_activeTab,
																	isSmall ? 2 : 1,
																)
																	? "meals"
																	: "mineShaft"
															}
														>
															Ingredients
														</Body1>
														<Spacing type="s4" />
													</div>
												),
												view: (
													<div>
														<Box
															padding={
																isSmall
																	? "20px 14px"
																	: "30px 24px"
															}
															style={{
																backgroundColor:
																	DS.colors
																		.wildSand,
																borderRadius:
																	DS.borderRadius
																		.body1,
															}}
														>
															<Table
																isSmall={isSmall}
																padding={22}
																tabItems={(
																	data?.recipe
																		?.Ingredients
																		?.Ingredients ||
																	[]
																)?.map((d) => {
																	return {
																		title: (
																			<Body2
																				fontWeight={
																					500
																				}
																			>
																				{
																					d?.Ingredients_Name
																				}
																			</Body2>
																		),
																		value: (
																			<Body2>
																				{d?.Unit}
																			</Body2>
																		),
																		subItems: [],
																	};
																})}
															/>
															<Spacing type="s32" />
															<ShowIf
																value={
																	(
																		data?.recipe
																			?.Ingredients
																			?.Image
																			?.url || ""
																	).length
																}
															>
																<img
																	src={
																		data?.recipe
																			?.Ingredients
																			?.Image?.url
																	}
																	alt="Ingredients"
																	style={{
																		width: "100%",
																		maxHeight: 400,
																		objectFit:
																			"contain",
																		backgroundColor:
																			DS.colors
																				.black,
																		borderRadius:
																			DS
																				.borderRadius
																				.body1,
																	}}
																/>
															</ShowIf>
														</Box>
													</div>
												),
											},
											{
												title: (
													<div>
														<Body1
															fontColor={
																isActive(
																	_activeTab,
																	isSmall ? 3 : 2,
																)
																	? "meals"
																	: "mineShaft"
															}
														>
															Instructions
														</Body1>
														<Spacing type="s4" />
													</div>
												),
												view: (
													<div>
														<ShowIf
															value={
																(
																	data?.recipe
																		?.Instructions
																		?.Variations ||
																	""
																)?.length
															}
														>
															<Box
																padding={
																	isSmall
																		? "20px 14px"
																		: "30px 24px"
																}
																style={{
																	backgroundColor:
																		"#ECF6F0",
																	borderRadius:
																		DS
																			.borderRadius
																			.body1,
																}}
															>
																<Body1>
																	Variations
																</Body1>
																<Spacing type="s16" />
																<Body2>
																	{
																		data?.recipe
																			?.Instructions
																			?.Variations
																	}
																</Body2>
															</Box>
															<Spacing type="s16" />
															<Box
																padding={
																	isSmall
																		? "20px 14px"
																		: "30px 24px"
																}
																style={{
																	backgroundColor:
																		DS.colors
																			.wildSand,
																	borderRadius:
																		DS
																			.borderRadius
																			.body1,
																}}
															>
																<Body1>
																	Cooking
																	Instructions
																</Body1>
																<Spacing type="s16" />
																{(
																	data?.recipe
																		?.Instructions
																		?.Steps || []
																).map(
																	(
																		step,
																		index,
																	) => {
																		return (
																			<div
																				key={
																					index
																				}
																			>
																				<Subtitle1 fontColor="meals">
																					Step{" "}
																					{index +
																						1}
																				</Subtitle1>
																				<Spacing type="s8" />
																				<Body2
																					fontWeight={
																						400
																					}
																				>
																					{
																						step?.Step_Description
																					}
																				</Body2>
																				<ShowIf
																					value={
																						(
																							step?.Step_Images ||
																							[]
																						)
																							?.length >
																						0
																					}
																				>
																					<Spacing type="s16" />
																					<ImageSlider
																						showActiveIndex
																						width="100%"
																						height={
																							isMobile
																								? 215
																								: 400
																						}
																						images={(
																							step?.Step_Images ||
																							[]
																						)
																							.map(
																								(
																									d,
																								) =>
																									d?.url ||
																									"",
																							)
																							.filter(
																								(
																									d,
																								) =>
																									d.length,
																							)}
																					/>
																				</ShowIf>
																				<Spacing type="s24" />
																			</div>
																		);
																	},
																)}
															</Box>
														</ShowIf>
													</div>
												),
											},
										]}
									/>
								</Box>
							</div>
						</Box>
					</Wrapper>
				</ShowIf>
				<ShowIf value={error}>
					<FlexCenter>
						<Body1>Failed to load Recipe</Body1>
					</FlexCenter>
					<Spacing type="s24" />
					<FlexCenter>
						<Button width="100px">
							<Body2
								as="span"
								fontColor="white"
								onClick={() => {
									refetch();
								}}
							>
								Retry
							</Body2>
						</Button>
					</FlexCenter>
				</ShowIf>
			</PageModal>
		);

	return (
		<Redirect to="/dashboard/meals/recipes" />
	);
};

export default RecipesDetails;
