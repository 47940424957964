import { DS, toPX } from "@workshore/nyaari-ds";
import React, { FC } from "react";
import styled from "styled-components";

const DashboardItemCardContainer = styled.div<{
	width: number | string;
	height: number | string;
}>`
	background-color: ${DS.colors.white};
	overflow: hidden;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);

	${({ width, height }) => `
width: ${toPX(width)};
height: ${toPX(height)};
border-radius:5px;
display:flex;
flex-direction:column;
`}
`;

const DashboardItemCardImg = styled.div<{
	url: string;
	height: number | string;
}>`
	background-color: ${DS.colors.lightGray};
	${({ url, height }) => `
background:url(${url});
height: ${toPX(height)};
`}
	flex-shrink:0;
	background-size: cover;
	background-position: center;
`;

const DashboardItemCard: FC<{
	width?: number | string;
	height?: number | string;
	imageHeight?: number | string;
	imageUrl: string;
}> = ({
	width = "100%",
	height = 290,
	imageHeight = 200,
	imageUrl,
	children,
}) => {
	return (
		<DashboardItemCardContainer
			width={width}
			height={height}
		>
			<DashboardItemCardImg
				url={imageUrl.replace(
					"amazonaws.com/",
					"amazonaws.com/small_",
				)}
				height={imageHeight}
			/>
			{children}
		</DashboardItemCardContainer>
	);
};

export default DashboardItemCard;
