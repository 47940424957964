import { useScreenWidth } from "@tinyd/usescreensize";
import useSharedState from "@tinyd/usesharedstate";
import dayjs from "dayjs";
import {
	Drawer,
	DS,
	IconDashboard,
	IconFoodIndex,
	IconGroceryList,
	IconMeals,
	IconMeditation,
	IconMovement,
	IconRecipes,
	IconMantra,
	Logo,
	Spacing,
	IconMasti,
	Box,
	Subtitle1,
	FlexCenter,
	Body2,
	Body1,
	ProfileImagePicker,
	Caption,
	ListItem,
} from "@workshore/nyaari-ds";
import { RefProps } from "@workshore/nyaari-ds/src/components/Drawer/Drawer";
import React, { useEffect, useRef } from "react";
import {
	Link,
	useHistory,
} from "react-router-dom";
import styled from "styled-components";
import useIsTablet from "../../hooks/useIsTablet/useIsTablet";
import { useMeValue } from "../../services/me";
import { PROFILE_PLACEHOLDER } from "../../utils/constant";
import DashboardMenu, {
	DashboardMenuItem,
} from "../DashboardMenu/DashboardMenu";
import { PublicMenuComponent } from "../PublicMenu/PublicMenu";
import ShowIf from "../ShowIf/ShowIf";

const DrawerContainer = styled.div`
	min-height: calc(
		100vh - ${DS.wrapper.fluid.padding * 2}px
	);
	margin-top: ${DS.wrapper.fluid.padding}px;
	margin-bottom: ${DS.wrapper.fluid.padding}px;
	margin-left: ${DS.wrapper.fluid.padding}px;
	width: calc(
		100% - ${DS.wrapper.fluid.padding}px
	);
	max-width: calc(
		100% - ${DS.wrapper.fluid.padding}px
	);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const Left = () => {
	const isTablet = useIsTablet();

	const screenWidth = useScreenWidth();
	const isMobile =
		screenWidth <=
		DS.customBreakpoints.drawerBreakpoint;
	const history = useHistory();

	const ref = useRef<RefProps>({
		open: () => {},
		close: () => {},
		toggle: () => {},
	});
	const [show, setShow] = useSharedState(
		"@left_menu",
		false,
	);

	useEffect(() => {
		if (
			history.location.pathname.includes(
				"/dashboard",
			)
		)
			if (show) {
				ref.current.open();
			} else {
				ref.current.close();
			}
	}, [show, history]);
	return (
		<Drawer
			placement="left"
			ref={ref}
			onOutsideClick={() => {
				setShow(false);
			}}
		>
			{!isMobile && (
				<DrawerContainer>
					<div>
						<Logo />
						<Spacing type="s32" />
						<DashboardMenu
							items={[
								{
									text: "Dashboard",
									icon: <IconDashboard />,
									url: "/dashboard",
									activeIcon: (
										<IconDashboard active />
									),
									color: "mineShaft",
									activeColor: "redRibbon",
								},
								{
									icon: <IconMeals />,
									text: "Meals",
									url: "/dashboard/meals",
									color: "mineShaft",
									activeColor: "meals",
									activeIcon: (
										<IconMeals active />
									),
									subMenu: [
										{
											icon: <IconRecipes />,
											text: "Recipes",
											url: "/dashboard/meals/recipes",
											color: "boulder",
											activeColor: "mineShaft",
											activeIcon: (
												<IconRecipes active />
											),
										},
										{
											icon: <IconGroceryList />,
											text: "Grocery List",
											url: "/dashboard/meals/grocery-list",
											color: "boulder",
											activeColor: "mineShaft",
											activeIcon: (
												<IconGroceryList active />
											),
										},
										// {
										// 	icon: <IconFoodIndex />,
										// 	text: "Food Index",
										// 	url: "/dashboard/meals/food-index",
										// 	color: "boulder",
										// 	activeColor: "mineShaft",
										// 	activeIcon: (
										// 		<IconFoodIndex active />
										// 	),
										// },
									],
								},
								{
									icon: <IconMovement />,
									text: "Movement",
									url: "/dashboard/movement",
									color: "mineShaft",
									activeColor: "movement",
									activeIcon: (
										<IconMovement active />
									),
								},
								{
									icon: <IconMeditation />,
									text: "Meditation",
									url: "/dashboard/meditation",
									color: "mineShaft",
									activeColor: "meditation",
									activeIcon: (
										<IconMeditation active />
									),
								},
								{
									icon: <IconMantra />,
									text: "Mantra",
									url: "/dashboard/mantra",
									color: "mineShaft",
									activeColor: "mantra",
									activeIcon: (
										<IconMantra active />
									),
								},
								{
									icon: <IconMasti />,
									text: "Masti",
									url: "/dashboard/masti",
									color: "mineShaft",
									activeColor: "masti",
									activeIcon: (
										<IconMasti active />
									),
								},
							]}
						/>
					</div>
					<div>
						<DashboardMenu
							items={[
								{
									icon: (
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="15"
											height="14.413"
											viewBox="0 0 15 14.413"
										>
											<path
												id="blogging"
												d="M13.242.5H1.758A1.76,1.76,0,0,0,0,2.258v9.727a2.346,2.346,0,0,0,2.344,2.344H8.965a.586.586,0,1,0,0-1.172H2.344a1.173,1.173,0,0,1-1.172-1.172V4.045H13.828V9.436a.586.586,0,0,0,1.172,0V2.258A1.76,1.76,0,0,0,13.242.5ZM10.869,1.672a.586.586,0,1,1-.586.586A.587.587,0,0,1,10.869,1.672Zm2.93.586a.586.586,0,1,1-.586-.586A.587.587,0,0,1,13.8,2.258Zm-12.627,0a.587.587,0,0,1,.586-.586H9.212a1.754,1.754,0,0,0,.011,1.2H1.172ZM11.276,8.7a.586.586,0,0,0-.245-.147l-2.306-.7a.586.586,0,0,0-.735.717l.655,2.365a.585.585,0,0,0,.15.257L12,14.4a1.758,1.758,0,0,0,2.486-2.485ZM9.4,9.28l1.146.348L12.716,11.8l-.829.829L9.733,10.47Zm4.253,4.29a.586.586,0,0,1-.828,0l-.113-.114.829-.829.113.113A.586.586,0,0,1,13.656,13.571Zm0,0"
												transform="translate(0 -0.5)"
												fill="#333"
											/>
										</svg>
									),
									text: "Freemium",
									url: "/freemium-gyan",
									color: "mineShaft",
									activeColor: "mineShaft",
								},

								{
									icon: (
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="17.3"
											height="15.435"
											viewBox="0 0 17.3 15.435"
										>
											<path
												id="fair-trade"
												d="M15.717,31.141a4.431,4.431,0,0,0-6.234,0l-.763.741-.853-.853A4.613,4.613,0,0,0,1.343,37.55l.086.086-.169.169a1.667,1.667,0,0,0,0,2.346l.015.015a1.648,1.648,0,0,0,1.156.486,1.648,1.648,0,0,0,.486,1.156l.015.015a1.647,1.647,0,0,0,1.182.486,1.647,1.647,0,0,0,.485,1.13l.015.015a1.667,1.667,0,0,0,2.346,0l.222-.222.993.993a1.638,1.638,0,0,0,2.8-1.134,1.644,1.644,0,0,0,1.612-1.579,1.564,1.564,0,0,0,1.3-2.393l1.83-1.731a4.431,4.431,0,0,0,.01-6.247Zm-12.8,8.321a.665.665,0,0,1-.936,0l-.015-.015a.665.665,0,0,1,0-.936l.916-.916a.662.662,0,0,1,.936,0l.015.015a.662.662,0,0,1,0,.936Zm1.656,1.656a.665.665,0,0,1-.936,0L3.621,41.1a.665.665,0,0,1,0-.936l1.5-1.5a.665.665,0,0,1,.936,0l.015.015a.665.665,0,0,1,0,.936Zm2.275,1.037-.594.594a.665.665,0,0,1-.936,0L5.3,42.733a.665.665,0,0,1,0-.936L5.9,41.2a.665.665,0,0,1,.936,0l.015.015A.665.665,0,0,1,6.847,42.154Zm6.108-1.8a.563.563,0,0,1-.8,0l-.99-.99a.5.5,0,0,0-.705.705L11.4,41a.641.641,0,0,1-.907.907l-.929-.929a.5.5,0,1,0-.705.705l.924.924,0,.005a.641.641,0,0,1-.907.907L7.838,42.478a1.667,1.667,0,0,0-.286-1.965L7.537,40.5a1.654,1.654,0,0,0-.588-.38,1.667,1.667,0,0,0-.176-2.14l-.015-.015A1.666,1.666,0,0,0,4.953,37.6a1.65,1.65,0,0,0-.416-.7l-.015-.015a1.661,1.661,0,0,0-2.347,0l-.042.042-.086-.086a3.616,3.616,0,1,1,5.113-5.113L8,32.575l-1.6,1.554a1.79,1.79,0,1,0,2.511,2.553l.552-.552a.045.045,0,0,1,.063,0l3.424,3.424a.565.565,0,0,1,0,.8Zm2.062-3.682L13.2,38.387l-2.963-2.963a1.047,1.047,0,0,0-1.474,0l-.552.552A.793.793,0,1,1,7.1,34.845l3.081-2.993a3.413,3.413,0,0,1,4.836,4.816Z"
												transform="translate(0.15 -29.527)"
												fill="#333"
												stroke="#333"
												strokeWidth="0.3"
											/>
										</svg>
									),
									text: "Our Team",
									url: "/collaborators",
									color: "mineShaft",
									activeColor: "mineShaft",
								},
								{
									icon: (
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="16.446"
											height="16.446"
											viewBox="0 0 16.446 16.446"
										>
											<g
												id="Group_11277"
												data-name="Group 11277"
												transform="translate(-38.289 -390.495)"
											>
												<path
													id="Path_1573"
													data-name="Path 1573"
													d="M3.845,17.765a1.136,1.136,0,0,0,1.408,1.408L7.565,18.5a8.016,8.016,0,1,0-3.05-3.048Zm2.6-11.307a7.154,7.154,0,1,1,1.4,11.205A.431.431,0,0,0,7.5,17.62l-2.484.726A.274.274,0,0,1,4.667,18l.722-2.488a.431.431,0,0,0-.043-.345A7.144,7.144,0,0,1,6.441,6.458Z"
													transform="translate(35.018 387.195)"
													fill="#333"
													stroke="#333"
													strokeWidth="0.4"
												/>
												<path
													id="Path_1574"
													data-name="Path 1574"
													d="M25.261,34.935h0a3.384,3.384,0,0,0,3.577-.772l.405-.405a1.081,1.081,0,0,0-.295-1.738L26.8,30.985a.431.431,0,0,0-.5.09l-.667.69h-.017A3.517,3.517,0,0,1,23.655,29.8l.69-.69a.431.431,0,0,0,.083-.491l-1.034-2.145a1.081,1.081,0,0,0-1.738-.295l-.4.4a3.387,3.387,0,0,0-.776,3.574,8.156,8.156,0,0,0,4.784,4.784Zm-3.4-7.745.405-.405a.219.219,0,0,1,.345.06l.907,1.87-.509.509a.8.8,0,0,0-.172.85A4.38,4.38,0,0,0,25.3,32.561l.05.019a.807.807,0,0,0,.883-.191l.474-.493,1.864.9a.219.219,0,0,1,.06.345l-.405.405a2.524,2.524,0,0,1-2.662.584h0a7.3,7.3,0,0,1-4.275-4.275A2.524,2.524,0,0,1,21.865,27.189Z"
													transform="translate(21.129 368.688)"
													fill="#333"
													stroke="#333"
													strokeWidth="0.4"
												/>
												<path
													id="Path_1575"
													data-name="Path 1575"
													d="M59.706,32.616a.429.429,0,0,0,.3-.117l.826-.826a.432.432,0,0,0-.61-.61l-.833.819a.431.431,0,0,0,.305.736Z"
													transform="translate(-11.149 364.489)"
													fill="#333"
													stroke="#333"
													strokeWidth="0.4"
												/>
												<path
													id="Path_1576"
													data-name="Path 1576"
													d="M63.9,46.255a.431.431,0,0,0,.407.453l1.053.059h.024a.431.431,0,1,0,.024-.862l-1.053-.059a.431.431,0,0,0-.455.409Z"
													transform="translate(-14.981 352.151)"
													fill="#333"
													stroke="#333"
													strokeWidth="0.4"
												/>
											</g>
										</svg>
									),
									text: "Contact Us",
									url: "/contactus",
									color: "mineShaft",
									activeColor: "mineShaft",
								},
							]}
						/>
					</div>
				</DrawerContainer>
			)}
			{isMobile && (
				<Box margin="28px 40px 20px 20px">
					<PublicMenuComponent
						setShow={setShow}
						hideSignIn
					>
						<Box marginTop={44}>
							<Link
								to="/dashboard#from_menu"
								onClick={() => {
									setShow(false);
								}}
							>
								<ListItem
									leftIcon={<IconDashboard />}
									title={
										<Subtitle1>
											Dashboard
										</Subtitle1>
									}
								/>
							</Link>
						</Box>
					</PublicMenuComponent>
				</Box>
			)}
		</Drawer>
	);
};

const RightSideMenu: DashboardMenuItem[] = [
	{
		icon: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="21.246"
				height="23.589"
				viewBox="0 0 21.246 23.589"
			>
				<g
					id="user_1_"
					data-name="user (1)"
					transform="translate(-2.75 -1.75)"
				>
					<path
						id="Path_2383"
						data-name="Path 2383"
						d="M22.746,22.03V19.687A4.687,4.687,0,0,0,18.06,15H8.687A4.687,4.687,0,0,0,4,19.687V22.03"
						transform="translate(0 2.06)"
						fill="none"
						stroke="#333"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2.5"
					/>
					<circle
						id="Ellipse_168"
						data-name="Ellipse 168"
						cx="4.687"
						cy="4.687"
						r="4.687"
						transform="translate(8.91 3)"
						fill="none"
						stroke="#333"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2.5"
					/>
				</g>
			</svg>
		),
		text: "Show My Profile",
		url: "#sb_profile_edit",
		color: "mineShaft",
		activeColor: "mineShaft",
	},

	{
		icon: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="25.485"
				height="23.458"
				viewBox="0 0 25.485 23.458"
			>
				<g
					id="subscription"
					transform="translate(0.192 0.146)"
				>
					<path
						id="Path_1871"
						data-name="Path 1871"
						d="M23.625,247.22a.736.736,0,0,0-.819.644,10.123,10.123,0,0,1-19.8,1.491l.88.483a.737.737,0,0,0,.709-1.291l-2.26-1.241a.737.737,0,0,0-1,.29L.092,249.854a.737.737,0,0,0,1.29.711l.289-.525a11.6,11.6,0,0,0,22.6-2,.736.736,0,0,0-.644-.818Zm0,0"
						transform="translate(0 -235.072)"
						fill="#333"
						stroke="#333"
						strokeWidth="0.3"
					/>
					<path
						id="Path_1872"
						data-name="Path 1872"
						d="M51.8,6.294a.737.737,0,0,0-.968.385l-.232.538A11.673,11.673,0,0,0,47.027,2.48,11.483,11.483,0,0,0,39.862,0a11.593,11.593,0,0,0-11.343,9.18.737.737,0,0,0,.569.873.773.773,0,0,0,.873-.569,10.12,10.12,0,0,1,9.9-8.011,10.025,10.025,0,0,1,6.255,2.161,10.187,10.187,0,0,1,3.225,4.4l-.932-.4a.737.737,0,0,0-.582,1.353L50.2,10.013a.7.7,0,0,0,.967-.385l1.02-2.366a.736.736,0,0,0-.385-.968Zm0,0"
						transform="translate(-27.103 0)"
						fill="#333"
						stroke="#333"
						strokeWidth="0.3"
					/>
					<path
						id="Path_1873"
						data-name="Path 1873"
						d="M154.276,91.692a.737.737,0,0,0-.737.737v.3a2.811,2.811,0,0,0,2.809,2.806h.306v1.311a.737.737,0,1,0,1.473,0V95.53h.886v1.311a.737.737,0,1,0,1.473,0V95.53h.306a2.811,2.811,0,0,0,2.809-2.806V92.3a2.476,2.476,0,0,0-1.805-2.376l-1.31-.366V85.939h.306a1.336,1.336,0,0,1,1.336,1.333v.119a.736.736,0,1,0,1.473,0v-.119a2.811,2.811,0,0,0-2.809-2.806h-.306V83.26a.736.736,0,1,0-1.473,0v1.206h-.886V83.26a.737.737,0,1,0-1.473,0v1.206h-.306a2.811,2.811,0,0,0-2.809,2.806,2.476,2.476,0,0,0,1.8,2.376l1.31.366v4.042h-.306a1.336,1.336,0,0,1-1.336-1.333v-.3a.737.737,0,0,0-.737-.737Zm7.125-.353a1,1,0,0,1,.728.957v.427a1.336,1.336,0,0,1-1.336,1.333h-.306V91.084Zm-2.387-5.4v3.2l-.886-.247V85.939ZM155.74,88.23a1,1,0,0,1-.728-.957,1.336,1.336,0,0,1,1.336-1.333h.306v2.546Zm2.387,2.2.886.247v3.384h-.886Zm0,0"
						transform="translate(-146 -78.467)"
						fill="#333"
						stroke="#333"
						strokeWidth="0.3"
					/>
				</g>
			</svg>
		),
		text: "My Subscription",
		url: "#sb_subscription",
		color: "mineShaft",
		activeColor: "mineShaft",
	},
	{
		icon: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="17.342"
				height="17.84"
				viewBox="0 0 17.342 17.84"
			>
				<g
					id="Solid"
					transform="translate(-21.241 -24)"
				>
					<path
						id="Path_1865"
						data-name="Path 1865"
						d="M33.094,148.721a.923.923,0,0,0,1.3,1.3l3.48-3.48a.923.923,0,0,0,0-1.3l-3.48-3.48a.923.923,0,0,0-1.3,1.3l1.9,1.9H24.923a.923.923,0,0,0,0,1.846H35Z"
						transform="translate(0.434 -112.973)"
						fill="#333"
					/>
					<path
						id="Path_1866"
						data-name="Path 1866"
						d="M185.538,24h8.612a1.538,1.538,0,0,1,1.538,1.538v1.23a.923.923,0,0,1-1.845,0v-.923h-8V39.994h8v-.923a.923.923,0,0,1,1.845,0V40.3a1.538,1.538,0,0,1-1.538,1.538h-8.612A1.538,1.538,0,0,1,184,40.3V25.538A1.538,1.538,0,0,1,185.538,24Z"
						transform="translate(-162.759)"
						fill="#333"
					/>
				</g>
			</svg>
		),
		text: "Log Out",
		url: "/logout",
		color: "mineShaft",
		activeColor: "mineShaft",
	},
];

const Right = () => {
	const isTablet = useIsTablet();
	const history = useHistory();
	const user = useMeValue();
	console.log(user.createdAt);
	const day = dayjs(user.createdAt).add(
		user.trialDays || 0,
		"days",
	);
	const currentDateDiff = day.diff(
		dayjs().format("YYYY-MM-DD"),
		"days",
	);

	const ref = useRef<RefProps>({
		open: () => {},
		close: () => {},
		toggle: () => {},
	});
	const [show, setShow] = useSharedState(
		"@right_menu",
		false,
	);

	useEffect(() => {
		if (
			history.location.pathname.includes(
				"/dashboard",
			)
		)
			if (show) {
				ref.current.open();
			} else {
				ref.current.close();
			}
	}, [show, history]);
	return (
		<Drawer
			ref={ref}
			placement="right"
			onOutsideClick={() => {
				setShow(false);
			}}
		>
			<DrawerContainer
				style={{
					marginRight: DS.wrapper.fluid.padding,
					maxWidth: `calc(100% - ${
						DS.wrapper.fluid.padding * 2
					}px)`,
				}}
			>
				<div>
					<ShowIf
						value={user.subStatus !== "active"}
					>
						<FlexCenter>
							<Subtitle1 fontColor="redRibbon">
								{Math.max(
									0,
									Math.min(
										currentDateDiff + 1,
										user.trialDays || 0,
									),
								)}{" "}
								/ {user.trialDays || 0} Days
							</Subtitle1>
						</FlexCenter>
						<FlexCenter>
							<Body2>
								Remaining of Free Trial
							</Body2>
						</FlexCenter>
					</ShowIf>
					<ShowIf
						value={user.subStatus === "active"}
					>
						<FlexCenter>
							<Body2>
								You've Paid Subscription 🎉
							</Body2>
						</FlexCenter>
					</ShowIf>
					<Spacing type="s24" multiplier={1.5} />
					<FlexCenter>
						<ProfileImagePicker
							imageSrc={
								user.avatar?.url ||
								PROFILE_PLACEHOLDER
							}
							hideCameraIcon
						/>
					</FlexCenter>
					<FlexCenter>
						<Body1>{user.name}</Body1>
					</FlexCenter>
					<FlexCenter>
						<Caption fontColor="boulder">
							{user.email}
						</Caption>
					</FlexCenter>
					<Spacing type="s24" multiplier={1.5} />
					<DashboardMenu
						items={[
							// ...(isTablet
							// 	? ([
							// 			{
							// 				icon: <IconDashboard />,
							// 				text: "Dashboard",
							// 				url: "/dashboard#from_menu",
							// 				color: "mineShaft",
							// 				activeColor: "mineShaft",
							// 			},
							// 	  ] as DashboardMenuItem[])
							// 	: ([] as DashboardMenuItem[])),
							...RightSideMenu,
						]}
					/>
				</div>
			</DrawerContainer>
		</Drawer>
	);
};

const DashboardDrawer = { Left, Right };

export default DashboardDrawer;
