import {
	DS,
	FlexBetween,
	IconFoodIndex,
	IconGroceryList,
	IconRecipes,
} from "@workshore/nyaari-ds";
import React, {
	FC,
	useEffect,
	useState,
} from "react";
import styled from "styled-components";
import IconSearch from "../Icons/IconSearch";

import { useSharedStateValue } from "@tinyd/usesharedstate";
import { elementScroll } from "../../hooks/useElementScroll/useElementScroll";
import useDebounce from "../../hooks/useDebounce/useDebounce";
import ShowIf from "../ShowIf/ShowIf";
import {
	Link,
	useHistory,
} from "react-router-dom";
import IconClose from "../Icons/IconClose";
const DashboardSubHeaderContainer = styled.div`
	height: 50px;
	background-color: ${DS.colors.white};
	box-shadow: 0px 9px 11px rgba(0, 0, 0, 0.06);
	position: sticky;
	transition: all 0.3s ease-in;
	top: 0px;
`;

const IconBox = styled.div<{ active?: boolean }>`
	height: 50px;
	width: 50px;
	${({ active = false }) => `
	box-shadow:inset 0px -3px 0px ${
		active ? DS.colors.mineShaft : "transparent"
	};
	`}
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	& * {
		flex-shrink: 0;
	}
`;

const DashboardSubHeader: FC<{
	autoHide?: boolean;
	hideSearch?: boolean;
	onChildrenStateChange?: (show: boolean) => void;
}> = ({
	autoHide = true,
	hideSearch = false,
	children,
	onChildrenStateChange = () => {},
}) => {
	const { location } = useHistory();
	const [showChildren, setShowChildren] =
		useState(false);
	const scroll =
		useSharedStateValue<elementScroll>(
			"@scroll",
			{
				scrollDirection: "up",
				scrollX: 0,
				scrollY: 0,
			},
		);
	const debouncedScroll = useDebounce(
		scroll.scrollDirection === "down",
		100,
	);
	useEffect(() => {
		onChildrenStateChange(showChildren);
	}, [showChildren, onChildrenStateChange]);

	return (
		<DashboardSubHeaderContainer
			style={{
				top:
					debouncedScroll && autoHide ? -100 : 0,
			}}
		>
			<ShowIf value={!showChildren}>
				<FlexBetween>
					<ShowIf value={!hideSearch}>
						<IconBox
							style={{
								marginLeft: 5,
							}}
							onClick={() =>
								setShowChildren(true)
							}
						>
							<IconSearch size={18} />
						</IconBox>
					</ShowIf>
					<Link to="/dashboard/meals/recipes">
						<IconBox
							style={
								hideSearch
									? {
											marginLeft: 5,
									  }
									: {}
							}
							active={location.pathname.includes(
								"meals/recipes",
							)}
						>
							<IconRecipes
								width={25}
								height={28.66}
								active={location.pathname.includes(
									"meals/recipes",
								)}
							/>
						</IconBox>
					</Link>
					<Link to="/dashboard/meals/grocery-list">
						<IconBox
							active={location.pathname.includes(
								"meals/grocery-list",
							)}
						>
							<IconGroceryList
								width={25}
								height={28.69}
								active={location.pathname.includes(
									"meals/grocery-list",
								)}
							/>
						</IconBox>
					</Link>
					{/* <Link to="/dashboard/meals/food-index">
						<IconBox
							active={location.pathname.includes(
								"meals/food-index",
							)}
							style={{
								marginRight: 10,
							}}
						>
							<IconFoodIndex
								active={location.pathname.includes(
									"meals/food-index",
								)}
								width={27.13}
								height={25.48}
							/>
						</IconBox>
					</Link> */}
				</FlexBetween>
			</ShowIf>
			<ShowIf value={showChildren}>
				<FlexBetween>
					{children}
					<IconBox
						style={{
							position: "absolute",
							top: 0,
							right: 0,
							background: "white",
						}}
						onClick={() => setShowChildren(false)}
					>
						<IconClose strokeWidth={0.2} />
					</IconBox>
				</FlexBetween>
			</ShowIf>
		</DashboardSubHeaderContainer>
	);
};

export default DashboardSubHeader;
