import {
	useRef,
	useState,
	useEffect,
} from "react";
import {
	H3,
	Body1,
	Body2,
	Caption,
	Wrapper,
	Box,
	Spacing,
	Subtitle1,
	DS,
	BulletText,
	FlexCenter,
	FlexBetween,
	Button,
} from "@workshore/nyaari-ds";
import styled from "styled-components";

import { useGetMovementsByCategoryQuery } from "../../hooks/graphql/graphql";
import Loader from "../Loader/Loader";
// import Physiotherapy from "../../assets/images/Physiotherapy.jpg";
import useIsTablet from "../../hooks/useIsTablet/useIsTablet";
import useIsMobile from "../../hooks/useIsMobile/useIsMobile";
import BulletList from "../BulletList/BulletList";
import LayoutContainer from "../LayoutContainer/LayoutContainer";
import { useElementWidth } from "../../hooks/useElementSize/useElementSize";
import ShowIf from "../ShowIf/ShowIf";
import SearchBox, {
	searchResultsTypes,
} from "../SearchBox/SearchBox";
import IconClock from "../Icons/IconClock";
import IconFire from "../Icons/IconFire";
import {
	useHistory,
	useParams,
} from "react-router";
import Seperator from "../Seperator/Seperator";
import useDebounce from "../../hooks/useDebounce/useDebounce";

const Container = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-column-gap: 40px;
	grid-row-gap: 40px;
	margin: 25px 0;

	@media screen and (max-width: 1400px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media screen and (max-width: ${DS.breakPoints
			.l}px) {
		grid-template-columns: repeat(2, 1fr);
		justify-content: space-between;
		margin: 23px 0;
	}

	@media screen and (max-width: ${DS.breakPoints
			.m}px) {
		grid-template-columns: repeat(4, 1fr);
	}

	@media screen and (max-width: 768px) {
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: 25px;
		grid-row-gap: 22px;
	}

	@media screen and (max-width: 480px) {
		grid-template-columns: repeat(2, 1fr);
	}
`;

const StyledCaption = styled(Caption)`
	@media screen and (max-width: 360px) {
		font-size: 10px;
	}
`;

// card
const Card = styled.div<{
	isTablet: boolean;
}>`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	border-radius: 5px;
	background: white;
	box-shadow: 0px 3px 6px #0000001a;
	cursor: pointer;
	overflow: hidden;
	img {
		height: 200px;
		width: 100%;
		object-fit: cover;
	}
	@media screen and (max-width: ${DS.breakPoints
			.sm}px) {
		img {
			height: 155px;
		}
	}
`;

const CardContent = styled.div<{
	isMobile: boolean;
}>`
	margin: 9px 12px;

	${({ isMobile }) =>
		isMobile &&
		`
    margin: 3px 10px;
  `};
`;

const MovementListing = () => {
	const [keyword, setKeyword] = useState("");
	const ref = useRef<HTMLDivElement>(null);
	const [searchResults, setSearchResults] =
		useState<searchResultsTypes[]>([]);
	const [showResults, setShowResults] =
		useState(false);
	const searchKeyword = useDebounce(keyword);

	const history = useHistory();
	const isMobile = useIsMobile();
	const isTablet = useIsTablet();
	const elementWidth = useElementWidth(ref);
	const isSmall = elementWidth <= 750;

	const { category_id: categoryId } =
		useParams<{ category_id: string }>();
	// console.log(category_id);
	const { data, loading, error, refetch } =
		useGetMovementsByCategoryQuery({
			variables: {
				categoryId,
			},
		});
	// console.log(data);

	useEffect(() => {
		if (searchKeyword.length > 1) {
			let searchRes: searchResultsTypes[] = [];
			data?.movements?.forEach((movement) => {
				if (
					movement?.title
						.toLocaleLowerCase()
						.includes(
							searchKeyword.toLocaleLowerCase(),
						)
				) {
					searchRes.push({
						image: movement.thumbnail?.url || "",
						text: movement.title || "",
						url: `/dashboard/movement/${movement?.movement_category?.id}/${movement.id}`,
						isKeyword: false,
					});
				}
			});
			setSearchResults(searchRes);
			setShowResults(true);
		} else {
			setSearchResults([]);
			setShowResults(false);
		}
	}, [data, searchKeyword]);

	return (
		<LayoutContainer
			ref={ref}
			backgroundColor="wildSand"
		>
			<ShowIf value={loading}>
				<div
					style={{
						height: "100vh",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Loader />
				</div>
			</ShowIf>
			<ShowIf value={error}>
				<div
					style={{
						height: "90vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					{error &&
					error!.message.includes(
						"Cast to ObjectId failed",
					) ? (
						<div>
							<Body1>
								Oops! Page does not exist.
							</Body1>
							<Spacing type="s24" />
							<FlexCenter>
								<Button
									width="200px"
									handleButtonClick={() =>
										history.push(
											"/dashboard/movement",
										)
									}
								>
									<Body2
										as="span"
										fontColor="white"
									>
										Go to Movement
									</Body2>
								</Button>
							</FlexCenter>
						</div>
					) : (
						<>
							<FlexCenter>
								<Body1>
									Failed to load Movement
								</Body1>
							</FlexCenter>
							<Spacing type="s24" />
							<FlexCenter>
								<Button width="100px">
									<Body2
										as="span"
										fontColor="white"
										onClick={() => {
											refetch();
										}}
									>
										Retry
									</Body2>
								</Button>
							</FlexCenter>
						</>
					)}
				</div>
			</ShowIf>
			{!loading && !error && (
				<ShowIf value={data?.movements?.length}>
					<Box
						paddingTop={isTablet ? 17 : 50}
						paddingBottom={90}
					>
						<Wrapper type="fluid">
							<ShowIf value={!isTablet}>
								<div
									style={{
										display: isSmall
											? "block"
											: "flex",
										alignItems: "center",
									}}
								>
									<H3>Movement</H3>
									<Spacing
										type={isSmall ? "s24" : "s64"}
									/>
									<SearchBox
										placeholder="Search"
										autoFocus
										value={keyword}
										onTextChange={(text) => {
											setKeyword(text);
										}}
										searchResults={searchResults}
										showResults={showResults}
										onSearchResultsCloseRequest={() => {
											setShowResults(false);
										}}
									/>
								</div>
								<Spacing type="s24" />
								<BulletList
									num={3}
									items={[
										<BulletText tickColor="movement">
											Four for fitness! Finish at
											least 4 routines a week.
										</BulletText>,
										<BulletText tickColor="movement">
											Keep it varied, keep it fun.
											Try different routines.
										</BulletText>,
										<BulletText tickColor="movement">
											Stay agile, avoid injuries.
											Be sure to stretch, physio
											or yoga.
										</BulletText>,
									]}
								/>
								<Spacing type="s32" />
								<Seperator />
							</ShowIf>
							<Subtitle1
								marginTop={
									isTablet ? "0px" : "24px"
								}
								marginBottom={
									isTablet ? "5px" : "0px"
								}
							>
								{data?.movements &&
									(data as any).movements[0]
										?.movement_category.name}
							</Subtitle1>
							{isTablet && (
								<BulletList
									num={3}
									items={[
										<BulletText tickColor="movement">
											Four for fitness! Finish at
											least 4 routines a week.
										</BulletText>,
										<BulletText tickColor="movement">
											Keep it varied, keep it fun.
											Try different routines.
										</BulletText>,
										<BulletText tickColor="movement">
											Stay agile, avoid injuries.
											Be sure to stretch, physio
											or yoga.
										</BulletText>,
									]}
								/>
							)}
							<Container>
								{data?.movements?.map(
									(movement) => (
										<Card
											key={movement?.id}
											isTablet={isTablet}
											onClick={() => {
												history.push(
													`/dashboard/movement/${
														(movement as any)
															.movement_category
															.id
													}/${
														(movement as any).id
													}`,
												);
											}}
										>
											<img
												src={
													movement?.thumbnail?.url
												}
												alt=""
											/>
											<CardContent
												isMobile={isMobile}
											>
												<Body1>
													{movement?.title}
												</Body1>
												<Caption
													marginBottom={
														isTablet
															? "6px"
															: "4px"
													}
													fontColor="boulder"
												>
													{
														movement?.collaborator
															?.name
													}
												</Caption>
												<FlexBetween>
													<FlexCenter>
														<IconClock color="movement" />
														<Spacing type="s4" />
														<StyledCaption
															fontWeight={500}
														>
															{movement?.duration}
														</StyledCaption>
													</FlexCenter>
													<FlexCenter>
														<IconFire color="movement" />
														<Spacing type="s4" />
														<StyledCaption
															fontWeight={500}
														>
															{
																movement?.calories_burn
															}
														</StyledCaption>
													</FlexCenter>
												</FlexBetween>
											</CardContent>
										</Card>
									),
								)}
							</Container>
						</Wrapper>
					</Box>
				</ShowIf>
			)}
		</LayoutContainer>
	);
};

export default MovementListing;
