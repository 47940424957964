import { useRef } from "react";
import styled from "styled-components";
import {
	H3,
	Body1,
	Body2,
	Wrapper,
	DS,
	Spacing,
	FlexCenter,
	Button,
} from "@workshore/nyaari-ds";

import { useGetMeditationDetailsQuery } from "../../hooks/graphql/graphql";
import Loader from "../Loader/Loader";
import PageModal from "../PageModal/PageModal";
import LabeledIcon from "../LabeledIcon/LabeledIcon";
// import IconFire from "../Icons/IconFire";
import IconUser from "../Icons/IconUser";
import IconClock from "../Icons/IconClock";
import useIsTablet from "../../hooks/useIsTablet/useIsTablet";
import ShowIf from "../ShowIf/ShowIf";
import {
	useHistory,
	useParams,
} from "react-router";
import HTMLParser from "../HTMLParser";
import ScrollContainer from "react-indiana-drag-scroll";
import VideoPlayer1 from "../VideoPlayer1/VideoPlayer1";
import Seperator from "../Seperator/Seperator";
import useIsMobile from "../../hooks/useIsMobile/useIsMobile";

const Header = styled.div<{ isTablet: boolean }>`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-top: 20px;
	margin-bottom: 30px;

	${({ isTablet }) =>
		isTablet &&
		`
    flex-direction: column;
    padding-top: 12px;
		margin-bottom: 0px;
  `}
`;

const Details = styled.div<{ isTablet: boolean }>`
	display: flex;
	flex-direction: row;
	> * {
		border-radius: 5px;
		background: ${DS.colors.wildSand};
		padding: 6px 12px;
	}
	> :not(:last-child) {
		margin-right: 20px;
	}

	${({ isTablet }) =>
		isTablet &&
		`
    margin-top: 12px
  `}
`;

function MeditationDetails() {
	const isTablet = useIsTablet();
	const history = useHistory();
	const ref = useRef<HTMLDivElement>(null);
	const isMobile = useIsMobile();

	const { meditation_id: id } =
		useParams<{ meditation_id: string }>();
	// console.log(id);
	const { data, loading, error, refetch } =
		useGetMeditationDetailsQuery({
			variables: {
				id,
			},
		});
	// console.log(data);

	return (
		<>
			<ShowIf value={loading}>
				<PageModal>
					<Loader />
				</PageModal>
			</ShowIf>
			<ShowIf value={error}>
				<PageModal>
					<div
						style={{
							height: "90vh",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						{error &&
						error!.message.includes(
							"Cast to ObjectId failed",
						) ? (
							<div>
								<Body1>
									Oops! Page does not exist.
								</Body1>
								<Spacing type="s24" />
								<FlexCenter>
									<Button
										width="200px"
										handleButtonClick={() =>
											history.push(
												"/dashboard/meditation",
											)
										}
									>
										<Body2
											as="span"
											fontColor="white"
										>
											Go to Meditation
										</Body2>
									</Button>
								</FlexCenter>
							</div>
						) : (
							<>
								<FlexCenter>
									<Body1>
										Failed to load Meditation
									</Body1>
								</FlexCenter>
								<Spacing type="s24" />
								<FlexCenter>
									<Button width="100px">
										<Body2
											as="span"
											fontColor="white"
											onClick={() => {
												refetch();
											}}
										>
											Retry
										</Body2>
									</Button>
								</FlexCenter>
							</>
						)}
					</div>
				</PageModal>
			</ShowIf>
			{!loading && !error && (
				<ShowIf value={data?.meditation}>
					<PageModal>
						{isTablet && (
							<>
								{data?.meditation?.vimeo_url && (
									<VideoPlayer1
										url={
											data?.meditation?.vimeo_url
										}
									/>
								)}
							</>
						)}
						<Wrapper ref={ref}>
							<Header isTablet={isTablet}>
								<H3>{data?.meditation?.title}</H3>
								<ScrollContainer horizontal>
									<Details isTablet={isTablet}>
										<LabeledIcon
											icon={
												<IconClock color="meditation" />
											}
											text={
												data?.meditation
													?.duration!
											}
										/>
										{/* <LabeledIcon
											icon={
												<IconFire color="meditation" />
											}
											text={
												data?.meditation
													?.calories_burn! as unknown as string
											}
										/> */}
										<LabeledIcon
											icon={
												<IconUser color="meditation" />
											}
											text={
												data?.meditation
													?.collaborator?.name!
											}
										/>
									</Details>
								</ScrollContainer>
							</Header>
							{isMobile && (
								<>
									<Spacing type="s24" />
									<Seperator
										height={2}
										color="meditation"
									/>
								</>
							)}
							<div>
								{!isTablet && (
									<>
										{data?.meditation
											?.vimeo_url && (
											<VideoPlayer1
												url={
													data?.meditation
														?.vimeo_url
												}
											/>
										)}
									</>
								)}
								<HTMLParser
									data={
										data?.meditation?.content!
									}
								/>
								<Spacing
									type={isTablet ? "s8" : "s32"}
								/>
							</div>
						</Wrapper>
					</PageModal>
				</ShowIf>
			)}
		</>
	);
}

export default MeditationDetails;
